import React, {useEffect, useState} from 'react';
import {Storage} from 'aws-amplify';
import {IonImg} from "@ionic/react";

const S3Image: React.FC<{ name: string, fileName: any, style?: any }> = (
    {
        name,
        fileName,
        style = {}
    }
) => {

    const [src, setSrc] = useState<string>('https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y');

    useEffect(() => {
        if(fileName){
            Storage.vault.get(fileName).then(setSrc)
        }
    }, [fileName])

    return <IonImg style={style} alt={name} src={src}/>

}
export default S3Image;