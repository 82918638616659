import React, {Dispatch, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState, LangContextS} from "../../store";
import {Auth} from "aws-amplify";
import {setSgUserAction, setUserAction} from "../../store/actions/AuthenticationActions";
import AuthService from "../../services/AuthService";


const Mfa: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const {user} = useSelector<RootState, any>((state: RootState) => state.authReducer);
    const {lang} = useSelector<RootState, any>((state: RootState) => state.langReducer);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const LangMapper: any = React.useContext(LangContextS);
    const [form]: any = null;

    const confirmSignIn = () => {
        const code = form.getFieldValue('code');
        let retrievingUser = false;
        if (code && user) {
            setInProgress(true);
            Auth.confirmSignIn(
                user,
                code,
                'SOFTWARE_TOKEN_MFA'
            )
                .then((loggedUser) => {
                    retrievingUser = true;
                    AuthService.getUser()
                        .then((res: any) => {
                   //         message.success('Successfully logged in');
                            const userData = JSON.parse(res);
                            console.log('Successfully logged in - MFA function', userData);
                            dispatch(setSgUserAction(userData));
                            dispatch(setUserAction({user: user, status: 'SIGNED_IN'}))
                        })
                        .catch((err: any) => {
                     //       message.success('An error occurred, please try again or contact support');
                        })
                        .finally(() => {
                            setInProgress(false);
                        })
                })
                .catch((err) => {
            //        message.error('Wrong code.');
                    console.log('error mfa', err)
                })
                .finally(() => {
                    if (!retrievingUser) {
                        setInProgress(false);
                    }
                });
        } else {
            console.log('code', code, 'user', user);
        }
    }


    return ( <></>/**
        <div className='login-wallpaper'>
            <Row className={'minH100'} align={"middle"} justify={'center'} style={{minHeight: '100vh'}}>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div style={{padding: '2em'}}>
                        <Typography.Title level={2}>
                            {lang === 'it' ? 'Autenticazione a due fattori' : 'Two factor authenticator'}
                        </Typography.Title>
                        <Typography.Paragraph
                            style={{marginBottom: 30}}>{lang === 'it' ? 'Inserisci il codice dall\'app sul tuo telefono' : 'Insert the code from your phone authenticator'}</Typography.Paragraph>

                        <ConfigProvider>
                            <Form data-testid="confirm-user-form"
                                  layout={'vertical'}
                                  size={'large'}
                                  style={{position: 'relative'}}
                                  form={form}
                                  onFinish={() => confirmSignIn()}
                            >

                                <Form.Item name={'code'} rules={[
                                    {
                                        required: true,
                                        message: lang === 'it' ? 'Codice di sicurezza' : 'Security code'
                                    }
                                ]}
                                           hasFeedback>
                                    <Input
                                        style={{borderRadius: 10, padding: '12px 11px', width: '100%'}}
                                        placeholder={lang === 'it' ? 'Inserisci il tuo codice di sicurezza' : 'Insert your security code'}/>
                                </Form.Item>

                                <SubmitButton text={LangMapper[lang].form?.confirm ?? 'Confirm'}
                                              isLoading={inProgress}/>

                                <a href={'https://www.google.com/landing/2step/'} target={'_blank'} rel={'nofollow'}>
                                    {lang === 'it' ? 'Cosa è un codice a due fattori?*' : 'What is this?*'}
                                </a>

                                <StyledFormLink onClick={() => dispatch(logoutAction())}>
                                    <FontAwesomeIcon icon={faArrowLeft}
                                                     style={{marginRight: 10}}/> {lang === 'it' ? 'Torna alla login' : 'Go back to login'}
                                </StyledFormLink>
                            </Form>
                        </ConfigProvider>


                    </div>
                </Col>

            </Row>
        </div>**/
    );
};

export default Mfa;
