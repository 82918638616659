import React, {Dispatch, useEffect, useState} from 'react';
import {useIonToast} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {resetMessageAction} from "../store/actions/MessageActions";


const Message: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const [present, dismiss] = useIonToast();
    const [queue, setQueue] = useState<string[]>([])
    const {text, type} = useSelector<RootState, any>((state: RootState) => state.messageReducer);

    useEffect(() => {
        if(!text) return;
        if(!Array.isArray(text)){
            present({
                message: text,
                color: type,
                duration: 2200,
                onDidDismiss: () => dispatch(resetMessageAction())
            })
        } else {
            setQueue(text)
        }
    }, [text]);

    useEffect(() => {
        console.log('queue is', queue)
        if (queue.length > 0) {
            console.log('hello doing something', queue[0])
            present({
                message: queue[0],
                color: type,
                duration: 1500,
                onDidDismiss: () => {
                    setQueue(queue.slice(1))
                    if(queue.slice(1).length === 0){
                        console.log('queue slice === 0 => reset')
                        dispatch(resetMessageAction());
                    }
                }
            })
        }
    }, [queue])


    return <>
    </>
}
export default Message;