import React from "react";

const loginEn = {
    emailConfirmed: 'Email has been confirmed',
    invalidEmail: 'Please insert a valid email',
    wrongCode: 'Wrong code',
    codeSent: 'The code has been sent, please check your email',
    genericError: 'An error occurred, please try again or contact support',
    wrongCredentials: 'Wrong username or password',
    passwordUpdated: 'Password successfully updated!',
    signUp: 'Sign up',
    signIn: 'Sign in',
    emailPlaceholder: 'Insert your email',
    passwordPlaceholder: 'Password',
    passwordConfirmPlaceholder: 'Confirm password'

}
export default loginEn;