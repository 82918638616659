import {Auth} from "aws-amplify";
import {setEmailAction, setStatusAction} from "../../store/actions/AuthenticationActions";
import {setMessageAction} from "../../store/actions/MessageActions";

class RegisterFunction {
    setSubmitInProgress: any;
    dispatch: any;
    setErrorCreatingUser: any;

    constructor(setSubmitInProgress: any, setErrorCreatingUser: any, dispatch: any) {
        this.setSubmitInProgress = setSubmitInProgress;
        this.setErrorCreatingUser = setErrorCreatingUser;
        this.dispatch = dispatch;
    }


    handleSignUp = (email: string, password: string) => {
        this.setSubmitInProgress(true);
        const username = email;

        Auth.signUp({
            username,
            password,
            attributes: {email},
            validationData: []
        })
            .then(() => {
                this.dispatch(setEmailAction(username));
                this.dispatch(setStatusAction('CONFIRM_USER_REGISTRATION'));
            })
            .catch(err => {
                this.dispatch(setMessageAction(err.message, 'danger'))
                this.setErrorCreatingUser(true);
                console.log('Error creating user', err)
            })
            .finally(() => {
                this.setSubmitInProgress(false);
            });

    };

}

export default RegisterFunction;
