import React, {Dispatch, useEffect, useRef} from 'react'
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useLocation} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarCircleUser, faCogs, faCreditCard,
    faForkKnife, faHatChef,
    faHome, faMap,
    faSignIn,
    faSignOut, faStar, faUserPlus
} from "@fortawesome/pro-solid-svg-icons";
import {logoutAction} from "../store/actions/AuthenticationActions";
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "../store";

const Menu: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const menu: any = useRef()
    const location = useLocation();
    const {status} = useSelector<RootState, any>((state: RootState) => state.authReducer);

    useEffect(() => {
        menu.current?.close();
    }, [location]);

    const logout = () => {
        dispatch(logoutAction());
    }

    return <IonMenu side="start" contentId="app" ref={menu}>
        <IonHeader>
            <IonToolbar className={'menu-header'}>
                <IonTitle>Table Booking</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            {status === 'SIGNED_IN' ?
                <IonList>
                    <IonItem routerLink={'/dashboard'}>
                        <FontAwesomeIcon icon={faHome} style={{marginRight: 15}}/> Home
                    </IonItem>


                    <IonItem routerLink={'/locations'}>
                        <FontAwesomeIcon icon={faForkKnife} style={{marginRight: 15}}/> I miei locali
                    </IonItem>

                    <IonItem routerLink={'/myBookings'}>
                        <FontAwesomeIcon icon={faCalendarCircleUser} style={{marginRight: 15}}/> Le mie prenotazioni
                    </IonItem>

                    <IonItem routerLink={'/favourites'} disabled>
                        <FontAwesomeIcon icon={faStar} style={{marginRight: 15}}/> Preferiti
                    </IonItem>

                    <IonItem routerLink={'/search'} disabled>
                        <FontAwesomeIcon icon={faHatChef} style={{marginRight: 15}}/> Cerca locale
                    </IonItem>

                    <IonItem routerLink={'/map'}>
                        <FontAwesomeIcon icon={faMap} style={{marginRight: 15}}/> Cerca su mappa
                    </IonItem>

                    <IonItem routerLink={'/settings'}>
                        <FontAwesomeIcon icon={faCogs} style={{marginRight: 15}}/> Impostazioni
                    </IonItem>

                    <IonItem routerLink={'/plans'}>
                        <FontAwesomeIcon icon={faCreditCard} style={{marginRight: 15}}/> Gestisci piano
                    </IonItem>
                </IonList> :
                <IonList>
                    <IonItem routerLink={'/dashboard'}>
                        <FontAwesomeIcon icon={faSignIn} style={{marginRight: 15}}/> Accedi
                    </IonItem>
                    <IonItem routerLink={'/signUp'}>
                        <FontAwesomeIcon icon={faUserPlus} style={{marginRight: 15}}/> Registrati
                    </IonItem>
                </IonList>
            }
        </IonContent>

        <IonFooter>
            <IonToolbar>
                <IonButton onClick={() => logout()} fill={'clear'} expand={'block'}>
                    <FontAwesomeIcon icon={faSignOut} style={{marginRight: 15}}/> Logout
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </IonMenu>
}

export default Menu;