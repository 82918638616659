import React, {useEffect, useRef, useState} from 'react'
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import {Room} from "../interfaces/Room"
import TableEditor from "./room_components/TableEditor";


const RoomPageExternalTable: React.FC = () => {
    const [room, setRoom] = useState<Room>();
    const roomEditorRef: any = useRef();

    useEffect(() => {
        const handler = (event: any) => {
            try {
                const data = JSON.parse(event.data);
                if (data.room) {
                    setRoom(data.room)
                }
                if (data.save) {
                    const json = roomEditorRef.current.exposeJson();
                    window.parent.postMessage(JSON.stringify({type: 'save', json: json}), '*')

                    // @ts-ignore
                    if(window.ReactNativeWebView) {
                        // send data object to React Native (only string)
                        // @ts-ignore
                        window.ReactNativeWebView.postMessage(JSON.stringify({type: 'save', json: json}))
                    }
                }
            } catch (e) {
                console.log(e)
              //  window.parent.postMessage(JSON.stringify({type: 'getRoom'}), '*')
            }
        }

        document.addEventListener("message", handler)

        // clean up
        return () => document.removeEventListener("message", handler)
    }, []) // empty array => run only once


    return <IonPage>
        <IonContent>
            {room && <TableEditor room={room} ref={roomEditorRef}/>}
        </IonContent>
    </IonPage>
}

export default RoomPageExternalTable;