import {AxiosInterceptor} from "./AxiosInterceptor";
import axios, {AxiosInstance, AxiosResponse} from "axios";
import {Room} from "../interfaces/Room";
import {Table} from "../interfaces/Table";

export class RoomService {

    static createNewRoom = async (data: any): Promise<Room> => {
        const url = `${process.env.REACT_APP_URL}/rooms`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, data).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static updateRoom = async (id: number, data: any): Promise<Room> => {
        const url = `${process.env.REACT_APP_URL}/rooms/${id}`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.put(url, data).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static updateRoomStructure = async (id: number, location_id: number, structure: string): Promise<Room> => {
        const url = `${process.env.REACT_APP_URL}/room_structure/${id}`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.put(url, {
                    structure: structure,
                    location_id: location_id
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static deleteRoom = async (id: number): Promise<Room> => {
        const url = `${process.env.REACT_APP_URL}/rooms/${id}`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.delete(url);
            });
    };

    static updateTables = async (id: number, location_id: number, data: Table[]): Promise<Room> => {
        const url = `${process.env.REACT_APP_URL}/room_tables/${id}`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.put(url, {
                    location_id: location_id,
                    tables: data
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };


    static checkAvailability = async (date: any, location_id: number, room_id?: number, start?: number, end?: number  ): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/room_bookings/${location_id}`;
        return await   axios.post(url, {
            date: date,
            room_id: room_id,
            start: start,
            end: end
        }).then(r => r.data);
    };

}