import axios, {AxiosInstance, AxiosResponse} from "axios";
import {CognitoRefreshToken} from "../interfaces/CognitoRefreshToken";
import {AxiosInterceptor} from "./AxiosInterceptor";

export default class AuthService {

    static refreshToken = async (refreshToken: CognitoRefreshToken, deviceKey: string): Promise<any> => {
        return await axios.post(`${process.env.REACT_APP_URL}/user/refresh_token`, {
            refresh_token: refreshToken.getToken(),
            device_key: deviceKey,
            is_production: process.env.REACT_APP_MODE,
            domain: process.env.REACT_APP_ID,
        }).then(res => {
            return res.data;
        });
    };

    static getUser = async (): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/user`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static updateUserAttribute = async (): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/user/updateCognitoAttribute`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    }

}
