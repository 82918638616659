import {fabric} from "fabric";

interface AdvancedIRectOptions extends fabric.IRectOptions{
    id?: string
}
interface AdvancedICircleOptions extends fabric.ICircleOptions{
    id?: string
}
interface AdvancedITriangleOptions extends fabric.ITriangleOptions{
    id?: string
}
interface AdvancedIPolylineOptions extends fabric.IPolylineOptions{
    id?: string
}
interface AdvancedIGroupOptions extends fabric.IGroupOptions{
    id?: string;
    table: any;
    rectId: any;
}

export class AdvancedRect extends fabric.Rect {
    constructor(options?: AdvancedIRectOptions) {
        super(options);
    }
}
export class AdvancedCircle extends fabric.Circle {
    constructor(options?: AdvancedICircleOptions) {
        super(options);
    }
}
export class AdvancedTriangle extends fabric.Triangle{
    constructor(options?: AdvancedITriangleOptions) {
        super(options);
    }
}
export class AdvancedPolygon extends fabric.Polygon{
    constructor(points: Array<{ x: number; y: number }>, options?: AdvancedIPolylineOptions) {
        super(points, options);
    }
}

export class AdvancedGroup extends fabric.Group{
    constructor(objects: fabric.Object[], options?: AdvancedIGroupOptions, isAlreadyGrouped?: boolean) {
        super(objects, options, isAlreadyGrouped);
    }
}

export class AdvancedObject extends Object{
    id?: string | undefined;
    constructor(options?: fabric.IObjectOptions) {
        super(options);
    }
}