import React, {Dispatch} from 'react';
import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/pro-solid-svg-icons";
import {useDispatch} from "react-redux";
import {setMessageAction} from "../../store/actions/MessageActions";

const CalendarPage: React.FC<{calendar: any, setCalendar: any}> = (
    {
        calendar,
        setCalendar
    }
    ) => {

    const dispatch: Dispatch<any> = useDispatch();

    const addInterval = (day: string) => {
        const c = {...calendar}
        if (c[day] === undefined) {
            c[day] = [['0:00', '0:00']];
        } else {
            c[day].push(['0:00', '0:00']);
        }
        setCalendar(c)
    }

    const removeInterval = (day: string, index: number) => {
        const c = {...calendar}
        if (c[day] !== undefined) {
            c[day].splice(index, 1);
            if(c[day].length === 0){
                delete c[day];
            }
            setCalendar(c)
        }
    }

    const onChangeSelect = (day: string, index: number, pos: 'start'|'end', value: string) => {
        const c = {...calendar}
        const oldValue = [...c[day][index]];
        oldValue[pos === 'start' ? 0 : 1] = value;

        const start = parseInt(oldValue[0].replace(':', ''));
        const end =  parseInt(oldValue[1].replace(':', ''));
        c[day][index] = oldValue;
        setCalendar(c)

        if(pos === 'end' && start > end){
            dispatch(setMessageAction("L\'ora di fine non può essere inferiore a quella d\'inizio", 'warning'))
            return;
        }


    }

    const printSelect = (name: string, day: string, index: number, pos: 'start' | 'end') => {
        const time: any = [];
        for (let i = 0; i <= 23; i++) {
            time.push(`${i}:00`, `${i}:30`)
        }
        return <IonSelect placeholder={name}
                          value={calendar[day][index][pos === 'start' ? 0 : 1]}
                          onIonChange={(e) => {
                              onChangeSelect(day, index, pos, e.detail.value)
                          }}>
            {time.map((t: string, index: number) => <IonSelectOption
                key={'tm-' + index}
                value={t}>
                {t}
            </IonSelectOption>)}
        </IonSelect>
    }

    return <>
        <IonAccordionGroup>
            {[
                {label: 'Lunedì', value: 'mon'},
                {label: 'Martedì', value: 'tue'},
                {label: 'Mercoledì', value: 'wed'},
                {label: 'Giovedì', value: 'thu'},
                {label: 'Venerdì', value: 'fri'},
                {label: 'Sabato', value: 'sat'},
                {label: 'Domenica', value: 'sun'}
            ].map((i: any, index: number) =>
                <IonAccordion key={'i-o-' + index} value={i.value}>
                    <IonItem slot={'header'}>
                        <IonCheckbox
                            style={{pointerEvents: 'none', opacity: 0.8, marginRight: 12}}
                            checked={calendar[i.value] !== undefined }/>
                        <IonLabel>{i.label}</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                        {calendar[i.value] !== undefined && calendar[i.value].map((ik: any, ind: number) => {
                            return <div key={'ind-' + ind + 'i' + index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderBottom: '1px solid lightgray'
                            }}>
                                <div style={{flex: 1, marginRight: 32}}>
                                    {printSelect('Inizio', i.value, ind, 'start')}
                                    {printSelect('Fine', i.value, ind, 'end')}
                                </div>
                                <IonButton color={'danger'}
                                           onClick={() => removeInterval(i.value, ind)}
                                           size={'small'}>
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </IonButton>
                            </div>
                        })}
                        <div style={{textAlign: 'center'}}>
                            <IonButton
                                onClick={() => addInterval(i.value)}
                                fill={'clear'}>Aggiungi orario lavorativo</IonButton>
                        </div>
                    </div>
                </IonAccordion>
            )}
        </IonAccordionGroup>
    </>
}

export default CalendarPage;