import React, {useEffect, useState} from 'react';
import {IonCard, IonCardContent, IonCardHeader, IonLabel, IonSegment, IonSegmentButton} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass, faLamp, faPencil} from "@fortawesome/pro-solid-svg-icons";
import RoomForm from "./RoomForm";
import RoomEditor from "./RoomEditor";
import TableEditor from "./TableEditor";
import {Room} from "../../interfaces/Room";
import {Location} from "../../interfaces/Location";
import {useLocation} from "react-router";

const RoomWizard: React.FC<{
    owner?: boolean,
    room: Room,
    setRoom: any,
    restaurant: Location,
    retrievingLocation: boolean,
}> = (
    {
        owner = false,
        room,
        setRoom,
        restaurant,
        retrievingLocation,
    }) => {

    const location = useLocation();
    const [activeStep, setActiveStep] = useState<string | undefined>('dettagli');

    const [doOnce, setDoOnce] = useState<boolean>(false)


    useEffect(() => {
        if (room && !doOnce) {
            const params = new URLSearchParams(location.search);
            const step = params.get('step')
            if (step) {
                setActiveStep(step);
            }
            setDoOnce(true);
        }
    }, [room]);

    const setNext = (step: string, room: Room) => {
        console.log('step and room', step, room)
        setRoom(room);

        setActiveStep(step);
    }


    return <>
        <IonCard style={{maxWidth: 600, margin: 'auto'}}>
            <IonCardHeader>
                <IonSegment scrollable value={activeStep}
                            style={{justifyContent: 'center', marginTop: 10}}
                            onIonChange={(e) => setActiveStep(e.detail.value)}>
                    <IonSegmentButton value="dettagli">
                        <IonLabel>Dettagli</IonLabel>
                        <FontAwesomeIcon icon={faPencil} style={{fontSize: 16, marginBottom: 20}}/>
                    </IonSegmentButton>
                    <IonSegmentButton value="superficie">
                        <IonLabel>Superficie</IonLabel>
                        <FontAwesomeIcon icon={faCompass} style={{fontSize: 16, marginBottom: 20}}/>
                    </IonSegmentButton>
                    <IonSegmentButton value="tavoli">
                        <IonLabel>Tavoli</IonLabel>
                        <FontAwesomeIcon icon={faLamp} style={{fontSize: 16, marginBottom: 20}}/>
                    </IonSegmentButton>
                </IonSegment>
            </IonCardHeader>

            <IonCardContent>
                {activeStep === 'dettagli' &&
                    <RoomForm retrievingLocation={retrievingLocation}
                              room={room}
                              location={restaurant}
                              next={() => {
                                  setActiveStep('superficie')
                              }
                              }/>}
                {(activeStep === 'superficie' && room) && <RoomEditor setNext={setNext} room={room}/>}
                {(activeStep === 'tavoli' && room) && <TableEditor  setNext={setNext}  room={room}/>}
            </IonCardContent>
        </IonCard>
    </>
}

export default RoomWizard;