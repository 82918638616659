import React from 'react';
import {Location} from "../../interfaces/Location";

const Collaborators: React.FC<{ location: Location, setLocation: any }> = (
    {
        location,
        setLocation
    }) => {
    return <>

    </>
}
export default Collaborators;