import {createReducer} from '@reduxjs/toolkit';
import {MessageState, SET_MESSAGE, RESET} from "../types/MessageTypes";


const initialState: MessageState = {
    text: '',
    type: 'info'
};


export const messageReducer = createReducer(initialState, (builder => {
    builder
        .addCase(SET_MESSAGE, (state: MessageState, action: any) => {
            state.text = action.payload.text;
            state.type = action.payload.type;
        })
        .addCase(RESET, (state: MessageState, action: any) => {
            state.text = '';
            state.type = 'info';
        });
    ;
}));
