import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {fabric} from "fabric";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faCircle,
    faHexagon,
    faRectangle,
    faSquare,
    faTriangle
} from "@fortawesome/pro-solid-svg-icons";
import {ColorResult, SketchPicker} from 'react-color'
import {IonButton, IonButtons, IonChip, IonContent, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import {
    AdvancedCircle,
    AdvancedObject,
    AdvancedPolygon,
    AdvancedRect,
    AdvancedTriangle
} from "../../vendors/fabricjs/Objects";
import {Room} from "../../interfaces/Room";
import {RoomService} from "../../services/RoomService";

const RoomEditor = forwardRef((props: {
    room: Room,
    setNext?: any
}, ref) => {

    const wrapper = useRef<HTMLDivElement>(null);

    const canvasEl = useRef<HTMLCanvasElement>(null)
    const canvasEl2 = useRef<HTMLCanvasElement>(null)
    const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>()

    const [color, setColor] = useState<ColorResult>();
    const {rgb, hex} = color || {};
    const [showPicker, setShowPicker] = useState<boolean>(false);

    const [shapes, setShapes] = useState<string[]>([]);

    const [activeObj, setActiveObj] = useState<boolean>(false)

    useEffect(() => {
        const deleteFunction = (event: KeyboardEvent, canvas: any) => {
            console.log(event.key)
            if (event.defaultPrevented) {
                return; // Do nothing if the event was already processed
            }

            switch (event.key) {
                case "Backspace":
                case "Delete":
                    // Do something for "esc" key press.
                    canvas.getActiveObjects().forEach((obj: any) => {
                        canvas.remove(obj)
                    });
                    canvas.discardActiveObject().renderAll()
                    break;
            }
        }

        const handleElement = (obj: any) => {
            setActiveObj(obj.selected);
        }

        if (props.room.location_id && canvasEl2) {
            drawGrid();
        }
        let canvas: any = null;
        if (props.room.location_id && canvasEl) {
            const options = {};
            canvas = new fabric.Canvas(canvasEl.current, options);
            setFabricCanvas(canvas);
            window.addEventListener("keydown", (event) => deleteFunction(event, canvas))
            canvas.on('object:scaling', (e: any) => trigger(e, canvas))
            canvas.on('object:added', (e: any) => trigger(e, canvas))
            canvas.on('object:removed', (e: any) => trigger(e, canvas))

            if (props.room.structure) {
                canvas.loadFromJSON(props.room.structure, function () {
                    console.log('loaded');
                });
            }

            canvas.on({
                'selection:updated': handleElement,
                'selection:created': handleElement,
                'selection:cleared': handleElement
            });

            // make the fabric.Canvas instance available to your app
            //     updateCanvasContext(canvas);
        }
        return () => {
            //           updateCanvasContext(null);
            if (canvas) {
                window.removeEventListener("keydown", (event) => deleteFunction(event, canvas))
                canvas.dispose();
            }
        }
    }, [canvasEl2, props.room])

    const makeId = (length: number = 8): string => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }


    const drawGrid = () => {
        const width = canvasEl2.current?.width;
        const height = canvasEl2.current?.height;

        let ctx = canvasEl2.current?.getContext('2d')
        if (!ctx || !width || !height) return;

        let s = 28
        let nX = Math.floor(width / s) - 2
        let nY = Math.floor(height / s) - 2
        let pX = width - nX * s
        let pY = height - nY * s
        let pL = Math.ceil(pX / 2) - 0.5
        let pT = Math.ceil(pY / 2) - 0.5
        let pR = width - nX * s - pL
        let pB = height - nY * s - pT

        ctx.strokeStyle = '#fbfbfb'
        ctx.beginPath()
        for (let x = pL; x <= width - pR; x += s) {
            ctx.moveTo(x, pT)
            ctx.lineTo(x, height - pB)
        }
        for (let y = pT; y <= height - pB; y += s) {
            ctx.moveTo(pL, y)
            ctx.lineTo(width - pR, y)
        }
        ctx.stroke()
    }

    const addRect = () => {
        // Initiate a Rect instance
        const rectangle = new AdvancedRect({
            width: 100,
            height: 150,
            fill: color ? color.hex : 'pink',
            id: makeId(),
            name: 'rettangolo'
        });

        // Render the Rect in canvas
        fabricCanvas?.add(rectangle);
    }

    const addCircle = () => {
        // Initiate a Rect instance
        const circle = new AdvancedCircle({
            radius: 40,
            fill: color ? color.hex : 'pink',
            id: makeId(),
            name: 'cerchio'
        });

        // Render the Rect in canvas
        fabricCanvas?.add(circle);
    }

    const addSquare = () => {
        // Initiate a Rect instance
        const square = new AdvancedRect({
            width: 150,
            height: 150,
            fill: color ? color.hex : 'pink',
            id: makeId(),
            name: 'quadrato'
        });

        // Render the Rect in canvas
        fabricCanvas?.add(square);
    }

    const addTriangle = () => {
        // Initiate a Rect instance
        const triangle = new AdvancedTriangle({
            top: 10,
            left: 5,
            width: 150,
            height: 100,
            fill: color ? color.hex : 'pink',
            id: makeId(),
            name: 'triangolo'
        });

        // Render the Rect in canvas
        fabricCanvas?.add(triangle);
    }

    const addHexagon = () => {
        // Initiate a Rect instance
        var trapezoid = [{x: -100, y: -50}, {x: 100, y: -50}, {x: 150, y: 50}, {x: -150, y: 50}];
        var emerald = [{x: 850, y: 75},
            {x: 958, y: 137.5},
            {x: 958, y: 262.5},
            {x: 850, y: 325},
            {x: 742, y: 262.5},
            {x: 742, y: 137.5},
        ];
        var star4 = [
            {x: 0, y: 0},
            {x: 100, y: 50},
            {x: 200, y: 0},
            {x: 150, y: 100},
            {x: 200, y: 200},
            {x: 100, y: 150},
            {x: 0, y: 200},
            {x: 50, y: 100},
            {x: 0, y: 0}
        ];
        var star5 = [{x: 350, y: 75},
            {x: 380, y: 160},
            {x: 470, y: 160},
            {x: 400, y: 215},
            {x: 423, y: 301},
            {x: 350, y: 250},
            {x: 277, y: 301},
            {x: 303, y: 215},
            {x: 231, y: 161},
            {x: 321, y: 161},];
        const shape = new Array(trapezoid, emerald, star4, star5);

        const polyg = new AdvancedPolygon(shape[1], {
            top: 15,
            left: 15,
            fill: color ? color.hex : 'pink',
            id: makeId(),
            name: 'esagono'
        });
        // Render the Rect in canvas
        fabricCanvas?.add(polyg);
    }

    const trigger = (e: any, cv: any) => {
        const n: any[] = [];
        cv.getObjects().forEach(function (o: any) {
            n.push(o)
        })
        setShapes(n);
        /*+
        let totalArea = 0;



        cv?.forEachObject(function(obj: any) {
            console.log('hello', obj)

            const minX = Math.max(0, obj.left);
            const minY = Math.max(0, obj.top);
            const right = (obj.left + (obj.width * obj.scaleX));
            const maxX = Math.min(right, cv.width);
            const bottom = (obj.top + (obj.height * obj.scaleY));
            const maxY = Math.min(bottom, cv.height);

            totalArea += ((maxX - minX) * (maxY - minY));
        });

        console.log('total area', totalArea)

         */
    }

    const acceptColor = () => {
        if (!fabricCanvas) return;
        fabricCanvas.getObjects().forEach((obj: any) => {
            obj.set("fill", hex);
        });
        fabricCanvas.renderAll();
        setShowPicker(false)
    }

    useImperativeHandle(ref, () => ({
        exposeJson() {
            if (!fabricCanvas) {
                console.log('alert no fabric canvas', fabricCanvas)
                return;
            }
            return fabricCanvas.toJSON();
        }
    }))


    const selectObject = (id: string) => {
        if (!fabricCanvas) return;
        const objects = fabricCanvas.getObjects();
        const len = objects.length;
        for (let k = 0; k < len; k++) {
            const o: AdvancedObject = objects[k];
            if (o.id === id) {
                // @ts-ignore
                fabricCanvas.setActiveObject(o);
                fabricCanvas.renderAll();
                return;
            }
        }
    }


    const goNext = () => {
        if (!fabricCanvas) {
            console.log('alert no fabric canvas', fabricCanvas)
            return;
        }
        const json = fabricCanvas.toJSON();
        console.log('json is:', json)

        RoomService.updateRoomStructure(props.room.id, props.room.location_id, json)
            .then((r: Room) => {
                console.log('updated room', r)
                if (props.setNext) {
                    props.setNext('tavoli', r)
                }
            })
    }

    return <>
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    margin: 'auto',
                    marginBottom: 16
                }}>
                <IonButton onClick={() => addTriangle()} size={'small'} color={'secondary'}>
                    <FontAwesomeIcon icon={faTriangle}/>
                </IonButton>
                <IonButton onClick={() => addRect()} size={'small'} color={'secondary'}>
                    <FontAwesomeIcon icon={faRectangle}/>
                </IonButton>
                <IonButton onClick={() => addCircle()} size={'small'} color={'secondary'}>
                    <FontAwesomeIcon icon={faCircle}/>
                </IonButton>
                <IonButton onClick={() => addSquare()} size={'small'} color={'secondary'}>
                    <FontAwesomeIcon icon={faSquare}/>
                </IonButton>
                <IonButton onClick={() => addHexagon()} size={'small'} color={'secondary'}>
                    <FontAwesomeIcon icon={faHexagon}/>
                </IonButton>
                <IonChip onClick={() => setShowPicker(!showPicker)}>
                    <FontAwesomeIcon icon={faSquare} color={
                        (
                            color &&
                            color.hex
                        ) ?
                            color.hex :
                            'pink'
                    }/>
                    <FontAwesomeIcon icon={faChevronDown} style={{marginLeft: 10}}/>
                </IonChip>
            </div>


            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                <div
                    ref={wrapper}
                    style={{
                        marginLeft: 5, marginRight: 5,
                        maxWidth: 300,
                        maxHeight: 300,
                        overflow: 'hidden'
                    }}>
                    <div

                        className={'container'}
                        style={{position: "relative"}}>
                        <canvas
                            style={{
                                position: "absolute",
                                left: -38,
                                right: 0,
                                bottom: 0,
                                top: -38,
                                border: "lightgrey 1px solid",
                            }}
                            width={380} height={380}
                            ref={canvasEl2}/>
                        <canvas
                            style={{
                                position: "relative",
                                border: "lightgrey 1px solid",
                                borderRadius: 4
                            }}
                            width={300} height={300}
                            ref={canvasEl}/>
                    </div>
                </div>


            </div>

            {activeObj && <IonModal isOpen={showPicker} style={{textAlign: 'center'}}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot={'end'}>
                            <IonButton onClick={() => setShowPicker(false)}>Chiudi</IonButton>
                            <IonButton onClick={() => acceptColor()}>Ok</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <div style={{margin: 'auto', width: 'fit-content', marginTop: 15}}>
                        <SketchPicker
                            color={rgb}
                            onChange={setColor}/>
                    </div>
                </IonContent>
            </IonModal>
            }

        </div>

        <div>
            {
                shapes.map((n: any, index: number) =>
                    <IonButton key={'obj-' + index}
                               fill={'clear'}
                               onClick={() => selectObject(n.id)}>
                        {n.name}
                    </IonButton>)
            }
        </div>

        {props.setNext && <div style={{textAlign: 'right'}}>
            <IonButton onClick={() => goNext()}>
                Avanti
            </IonButton>
        </div>}
    </>

});
export default RoomEditor;