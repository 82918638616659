import {Booking} from "../interfaces/Booking";
import {Table} from "../interfaces/Table";

export const doesBookingHasTable = (bookings: Booking[], id: number): boolean => {
    let found = false;

    bookings.map((bb: Booking) => {
        if (bb.tables) {
            const tables = JSON.parse(bb.tables);
            tables.map((t: Table) => {
                if (t.id === id) {
                    found = true;
                }
            })
        }
    })

    return found;
}

export const findBookingByTableId = (bookings: Booking[], id: number): Booking[] => {

    const response: Booking[] = [];

    bookings.map((bb: Booking) => {
        if (bb.tables) {
            const tables = JSON.parse(bb.tables);
            tables.map((t: Table) => {
                if (t.id === id) {
                    response.push(bb);
                }
            })
        }
    })

    console.log('response is', response)
    return response;
}