import React, {useEffect, useState} from 'react'
import {
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard, IonCardContent, IonCardHeader,
    IonContent,
    IonLabel,
    IonLoading,
    IonPage, IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import {useParams} from "react-router-dom";
import RoomEditor from "./room_components/RoomEditor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass, faLamp, faPencil} from "@fortawesome/pro-solid-svg-icons";
import RoomForm from "./room_components/RoomForm";
import {useLocation} from "react-router";
import Navbar from "../shared/Navbar";
import TableEditor from "./room_components/TableEditor";
import Footer from "../shared/Footer";
import {Location} from "../interfaces/Location";
import {Room} from "../interfaces/Room"
import {LocationService} from "../services/LocationService";
import RoomWizard from "./room_components/RoomWizard";


const RoomPage: React.FC = () => {


    let {id, roomId}: any = useParams();

    const [restaurant, setRestaurant] = useState<Location>();
    const [room, setRoom] = useState<Room>();
    const [retrievingLocation, setRetrievingLocation] = useState<boolean>(true);

    useEffect(() => {
        const init = async () => {
            LocationService.getLocation(id).then((lo: Location) => {
                setRestaurant(lo);
                setRoom(lo.rooms.find((ro: Room) => ro.id === parseInt(roomId)))
            })
                .finally(() => setRetrievingLocation(false))
        }
        init();
    }, [id])


    return <IonPage>
        <Navbar title={room ? (room.name ?? 'Nessun nome') : 'Caricamento...'}/>
        <IonContent>
            {retrievingLocation ?
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={retrievingLocation}
                    message={'Recupero informazioni...'}
                /> :
                (restaurant && room) ? <>
                    <IonBreadcrumbs>
                        <IonBreadcrumb href="/locations">Locations</IonBreadcrumb>
                        <IonBreadcrumb href={`/locations/${id}`}>{restaurant.name}</IonBreadcrumb>
                        <IonBreadcrumb href={`/locations/${id}/rooms/${roomId}`}>{room.name}</IonBreadcrumb>
                    </IonBreadcrumbs>

                    <RoomWizard
                        retrievingLocation={retrievingLocation}
                        setRoom={setRoom}
                        room={room}
                        restaurant={restaurant}/>

                </> : <>
                    Nessun risultato
                </>
            }


        </IonContent>
        <Footer/>
    </IonPage>
}

export default RoomPage;