import React, {useEffect, useState} from 'react'
import BookingWizard from "./BookingWizard";
import {LocationService} from "../services/LocationService";
import {Location} from "../interfaces/Location";
import {Room} from "../interfaces/Room";
import {Calendar} from "../interfaces/Calendar";
import {useParams} from "react-router-dom";
import {IonContent, IonPage, IonRefresher, IonRefresherContent, IonSpinner} from "@ionic/react";
import Footer from "../shared/Footer";


const IframeRoom: React.FC = () => {

    const {id, roomId}: any = useParams();
    const [location, setLocation] = useState<Location>();
    const [room, setRoom] = useState<Room>();
    const [calendars, setCalendars] = useState<Calendar[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const init = async () => {
            LocationService.getLocation(id)
                .then((l: Location) => {
                    setLocation(l)
                    setCalendars(l.calendars)
                    setRoom(l.rooms.find((r: Room) => r.id === parseInt(roomId)))
                }).finally(() => setIsLoading(false))
        }
        init();
    }, [])

    return <IonPage>
        <IonContent>
            {isLoading ? <div style={{textAlign: 'center', paddingTop: 150}}>
                    <IonSpinner/>
                </div> :
                (room && location) ?
                    <BookingWizard calendars={calendars}
                                   room={room}
                                   roomId={roomId}
                                   id={id}
                                   location={location}/>
                    : 'Error retrieving location'

            }
        </IonContent>
        <Footer/>
    </IonPage>
}

export default IframeRoom;