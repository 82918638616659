export const SET_MESSAGE = 'SET_MESSAGE';
export const RESET = 'RESET';

export interface MessageState {
    text: string | string[],
    type: 'success' | 'info' | 'warning' | 'danger'
}

interface setMessage {
    type: typeof SET_MESSAGE,
    payload: { text: string | string[], type: 'success' | 'info' | 'warning' | 'danger' }
}

interface reset {
    type: typeof RESET
}

export type MessageActionTypes = setMessage | reset
