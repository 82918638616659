import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {
    IonButton,
    IonImg,
    IonInput,
    IonItem, IonLabel,
    IonList,
    IonTextarea, useIonRouter,
} from "@ionic/react";
import {takePicture} from "../../functions/usePhotoGallery";
import {Storage} from "aws-amplify";
import {setMessageAction} from "../../store/actions/MessageActions";
import S3Image from "../../components/S3Image";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {Location} from "../../interfaces/Location";
import {RoomService} from "../../services/RoomService";
import {Room} from "../../interfaces/Room";
import Button from "../../components/Button";

const RoomForm: React.FC<{
    retrievingLocation: boolean,
    location: Location,
    next?: any,
    room?: Room
}> = (
    {
        retrievingLocation,
        location,
        next = null,
        room = null
    }) => {

    const dispatch: Dispatch<any> = useDispatch();
    const router = useIonRouter();

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [photo, setPhoto] = useState<any>();
    const [defaultPhoto, setDefaultPhoto] = useState<any>(''); // TODO PLACEHOLDER
    const [format, setFormat] = useState<string>();

    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
        if (room) {
            console.log(room)
            setName(room.name ?? '');
            setDescription(room.description ?? '');
            if (room.image) {
                setDefaultPhoto(room.image);
            }
        }
    }, [])

    const chooseImage = () => {
        takePicture().then(data => {
            setPhoto(data.webPath)
            setFormat(data.format)
        });
    }

    const goNext = async () => {
        setSaving(true)
        const options: any = {
            "name": name,
            "description": description,
            "location_id": location.id
        }
        let fileName: any = null;
        if (format) {
            if (room?.image) {
                await Storage.vault.remove(room?.image)
            }
            fileName = +new Date() + '.' + format;
            const p = await fetch(photo)
            const photoBlob = await p.blob();
            await Storage.vault.put(fileName, photoBlob, {
                contentType: `image/${format}`
            })
            options.image = fileName;
        }

        const done = room?.id ? await update(room.id, options) : await create(options);
        if (!done) {
            if (fileName) {
                await Storage.vault.remove(fileName)
            }
        }
        console.log('set saving false')
    }

    const update = async (id: number, options: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            RoomService.updateRoom(id, options)
                .then(async (r: any) => {
                    dispatch(setMessageAction('Stanza aggiornata', 'success'))
                    resetForm();
                    if (next === null) {
                        router.push(`./rooms/${r.id}?step=superficie`)
                    } else {
                        next();
                    }
                    resolve(true);
                }).catch(async (e) => {
                if (e.response && e.response.data) {
                    if(e.response.data.length > 15){
                        dispatch(setMessageAction('An error occurred', 'danger'))
                        return;
                    }
                    let errors: string[] = [];
                    for (const [key, value] of Object.entries(e.response.data)) {
                        if (Array.isArray(value)) {
                            value.map((v: string) => {
                                errors.push(v)
                            })
                        }
                    }
                    dispatch(setMessageAction(errors, 'danger'))
                }
                reject(false);
            }).finally(() =>        setSaving(false))
        })
    }

    const create = async (options: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            RoomService.createNewRoom(options)
                .then(async (r: any) => {
                    dispatch(setMessageAction('Nuova stanza creata', 'success'))
                    resetForm();

                    if (next === null) {
                        router.push(`./rooms/${r.id}?step=superficie`)
                    } else {
                        next();
                    }
                    resolve(true);
                }).catch(async (e) => {
                if (e.response && e.response.data) {
                    if(e.response.data.length > 15){
                        dispatch(setMessageAction('An error occurred', 'danger'))
                        return;
                    }
                    let errors: string[] = [];
                    for (const [key, value] of Object.entries(e.response.data)) {
                        if (Array.isArray(value)) {
                            value.map((v: string) => {
                                errors.push(v)
                            })
                        }
                    }
                    dispatch(setMessageAction(errors, 'danger'))
                }
                reject(false);
            }).finally(() =>        setSaving(false))
        })
    }

    const resetForm = () => {
        setPhoto(undefined)
        setFormat(undefined)
        setName('');
        setDescription('');
        setDefaultPhoto(''); // TODO PLACEHOLDER
    }

    return <>
        <IonList>
            <IonItem>
                <IonLabel position="floating">Nome</IonLabel>
                <IonInput value={name}
                          type={'text'}
                          onIonChange={(e) => setName(e.detail.value!)}/>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Descrizione</IonLabel>
                <IonTextarea value={description}
                             rows={5}
                             onIonChange={(e) => setDescription(e.detail.value!)}/>
            </IonItem>


            <IonItem style={{padding: 15}} lines={'none'}>
                {!photo ?
                    (
                        defaultPhoto === 'placeholder' ?
                            <IonImg src={defaultPhoto} style={{width: 200, height: 120}}></IonImg> :
                            <S3Image name={defaultPhoto} fileName={defaultPhoto}
                                     style={{width: 200, height: 120}}/>
                    ) :
                    <IonImg src={photo} style={{width: 200, height: 120}}></IonImg>
                }
                <IonButton style={{marginLeft: 50}}
                           color={'dark'} fill={'outline'}
                           expand="full" onClick={() => chooseImage()}>
                    Seleziona immagine
                </IonButton>
            </IonItem>
        </IonList>

        <div style={{textAlign: 'right'}}>
            <Button onClick={() => goNext()} loading={saving}>
                Avanti
            </Button>
        </div>
    </>

}

export default RoomForm;