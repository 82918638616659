import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {
    createAnimation,
    IonAvatar,
    IonButton, IonButtons,
    IonContent, IonFab, IonFabButton,
    IonHeader, IonImg, IonInput, IonItem, IonItemDivider, IonLabel,
    IonList,
    IonModal,
    IonPage, IonSpinner, IonTextarea,
    IonTitle,
    IonToolbar, useIonActionSheet, useIonAlert
} from "@ionic/react";
import {takePicture} from "../functions/usePhotoGallery";
import {Storage} from 'aws-amplify';
import {setMessageAction} from "../store/actions/MessageActions";
import {useDispatch} from "react-redux";
import S3Image from "../components/S3Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faMapMarked, faPlus, faWrench} from "@fortawesome/pro-solid-svg-icons";
import Navbar from "../shared/Navbar";
import CalendarPage from "./location_components/CalendarPage";
import Footer from "../shared/Footer";
import {LocationService} from "../services/LocationService";
import {Location} from "../interfaces/Location";
import Button from "../components/Button";
import {Calendar} from "../interfaces/Calendar";
import {GoogleMap} from "@capacitor/google-maps";
import {Geolocation} from "@capacitor/geolocation";
import {GmapService} from "../services/GmapService";
import {requestPermission} from "../permissions";
import {AndroidPermissions} from "@ionic-native/android-permissions";

const Locations: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const [locations, setLocations] = useState<Location[]>([]);
    const [retrievingLocation, setRetrievingLocation] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<number>();

    const modal = useRef<HTMLIonModalElement>(null);

    const [photo, setPhoto] = useState<any>();
    const [defaultPhoto, setDefaultPhoto] = useState<any>(''); // TODO PLACEHOLDER
    const [format, setFormat] = useState<string>();

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [zip, setZip] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [country, setCountry] = useState<string>('Italia');
    const [calendar, setCalendar] = useState<any>({});
    const [selectedModel, setSelectedModel] = useState<any>();
    const [latitude, setLatitude] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')

    const [isGmapOpen, setIsGmapOpen] = useState<boolean>(false)

    const chooseImage = () => {
        takePicture().then(data => {
            setPhoto(data.webPath)
            setFormat(data.format)
        });
    }

    const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;

        const backdropAnimation = createAnimation()
            .addElement(root?.querySelector('ion-backdrop')!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
            .addElement(root?.querySelector('.modal-wrapper')!)
            .keyframes([
                {offset: 0, opacity: '0', transform: 'scale(0)'},
                {offset: 1, opacity: '0.99', transform: 'scale(1)'},
            ]);

        return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: HTMLElement) => {
        return enterAnimation(baseEl).direction('reverse');
    };

    async function list() {
        LocationService.listMyLocations().then(setLocations)
            .finally(() => setRetrievingLocation(false))
    }

    useEffect(() => {
        requestPermission(AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION);
        requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION);
        requestPermission(AndroidPermissions.PERMISSION.CAMERA);
        list();
    }, []);

    const create = async () => {
        const fileName = +new Date() + '.' + format;
        const p = await fetch(photo)
        const photoBlob = await p.blob();
        setSaving(true)
        await Storage.vault.put(fileName, photoBlob, {
            contentType: `image/${format}`
        });
        LocationService.createNewLocation({
            "name": name,
            "description": description,
            "image": fileName,
            "address": address,
            "zip": zip,
            "city": city,
            "state": state,
            "country": country,
            "calendar": calendar,
            "latitude": latitude,
            "longitude": longitude
        }).then(r => {
            dispatch(setMessageAction('Nuovo locale creato', 'success'))
            setLocations([...locations, r])
            modal.current?.dismiss()
            resetForm();
        }).catch(e => {
            if (e.response && e.response.data) {
                let errors: string[] = [];
                if (e.response.data.length > 15) {
                    dispatch(setMessageAction('An error occurred', 'danger'))
                    return;
                }
                for (const [key, value] of Object.entries(e.response.data)) {
                    if (Array.isArray(value)) {
                        value.map((v: string) => {
                            errors.push(v)
                        })
                    }
                }
                dispatch(setMessageAction(errors, 'danger'))
            }

        })
            .finally(() => setSaving(false))
    }

    const resetForm = () => {
        setPhoto(undefined)
        setFormat(undefined)
        setName('');
        setDescription('');
        setAddress('');
        setZip('');
        setCity('');
        setState('');
        setDefaultPhoto(''); // TODO PLACEHOLDER
        setSelectedModel(undefined);
        setCalendar({});
    }

    const deleteLocation = async (l: Location) => {
        setDeleting(l.id)
        if (l.image) {
            await Storage.vault.remove(l.image)
        }
        LocationService.deleteLocation(l.id)
            .then(r => {
                setLocations([...locations.filter((xx: Location) => xx.id !== l.id)])
                dispatch(setMessageAction('Locale eliminato', 'success'))
            })
            .finally(() => setDeleting(undefined))
    }

    const update = async (original: Location) => {
        setSaving(true)
        const options: any = {
            "name": name,
            "description": description,
            "address": address,
            "zip": zip,
            "city": city,
            "state": state,
            "country": country,
            "calendar": calendar
        }

        if (photo && format) {
            const fileName = +new Date() + '.' + format;
            const p = await fetch(photo)
            const photoBlob = await p.blob();

            if (original.image) {
                await Storage.vault.remove(original.image)
            }

            await Storage.vault.put('locations/' + fileName, photoBlob, {
                contentType: `image/${format}`,
                level: 'public'
            })
            options.image = fileName;
        }

        LocationService.updateLocation(original.id, options).then(r => {
            dispatch(setMessageAction('Locale aggiornato', 'success'))
            setLocations([...locations.filter((l: Location) => l.id !== original.id), r])
            modal.current?.dismiss()
            resetForm();
        })
            .catch(e => {
                if (e.response && e.response.data) {
                    let errors: string[] = [];
                    if (e.response.data.length > 15) {
                        dispatch(setMessageAction('An error occurred', 'danger'))
                        return;
                    }
                    for (const [key, value] of Object.entries(e.response.data)) {
                        if (Array.isArray(value)) {
                            value.map((v: string) => {
                                errors.push(v)
                            })
                        }
                    }
                    dispatch(setMessageAction(errors, 'danger'))
                }

            })
            .finally(() => setSaving(false))
    }

    const [presentAlert] = useIonAlert();
    const [present] = useIonActionSheet();

    useEffect(() => {
        if (selectedModel && selectedModel.calendars) {
            const tempCalendar: any = {};
            selectedModel.calendars.map((c: Calendar) => {
                if (c.day_of_the_week in tempCalendar) {
                    tempCalendar[c.day_of_the_week].push(
                        [c.from, c.to]
                    );
                } else {
                    tempCalendar[c.day_of_the_week] = [
                        [c.from, c.to]
                    ]
                }
            })
            setCalendar(tempCalendar);
        } else {
            setCalendar({});
        }
    }, [selectedModel])


    const mapRef = useRef<HTMLElement>();
    let newMap: GoogleMap;


    const retrieveCoordinate = async () => {
        return new Promise((resolve, reject) => {
            console.log('retrieve coordinates')
            GmapService.geocode(address + ' ' + city + ' ' + zip + ' ' + state + ' ' + country)
                .then((r) => {
                    console.log('response is', r)
                    if (r.results && r.results.length > 0) {
                        const res = r.results[0];
                        resolve({
                            coords: {
                                latitude: res.geometry.location.lat,
                                longitude: res.geometry.location.lng
                            }
                        })
                    }
                })
                .catch((e) => {
                    console.log('an error occurred', e)
                    reject();
                })
        })
    }


    useEffect(() => {
        const init = async () => {
            console.log('init')
            if (!mapRef.current) return;
            console.log('start search')

            let coordinates: any = null;
            if (address && state && city) {
                coordinates = await retrieveCoordinate();
            }

            if (!coordinates) {
                console.log('setup default coordinates')
                coordinates = await Geolocation.getCurrentPosition();
            }

            console.log('coordinates', coordinates);
            if (!coordinates) return;
            newMap = await GoogleMap.create({
                id: 'location-map',
                element: mapRef.current,
                apiKey: process.env.REACT_APP_GMAP_KEY!,
                config: {
                    center: {
                        lat: coordinates.coords.latitude,
                        lng: coordinates.coords.longitude
                    },
                    zoom: 16
                }
            })

            await newMap.addMarker({
                title: 'Posizione locale',
                coordinate: {
                    lat: coordinates.coords.latitude,
                    lng: coordinates.coords.longitude
                },
                //iconUrl: '',

            });

            setLatitude(coordinates.coords.latitude)
            setLongitude(coordinates.coords.longitude)
        }

        if (isGmapOpen) {
            setTimeout(() => {
                init();
            }, 200);
        }

        return () => {
            if (newMap) {
                newMap.destroy();
            }
        }
    }, [isGmapOpen])


    return <IonPage>
        <Navbar title={'Locali'}/>
        <IonContent>
            {retrievingLocation ? <div style={{textAlign: 'center', paddingTop: 150}}>
                <IonSpinner/>
            </div> : <>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton id="open-modal">
                        <FontAwesomeIcon icon={faPlus}/>
                    </IonFabButton>
                </IonFab>


                {locations.length === 0 && <div style={{padding: '1em'}}>
                    Non hai ancora aggiunto locali.
                    <IonImg src={'assets/test-2.jpg'} style={{borderRadius: 15, marginBottom: 32, marginTop: 32}}/>
                    Clicca sul pulsante + in basso a destra per creare il tuo primo locale.
                </div>}


                <IonList>
                    {
                        locations.map((l: Location, index: number) => {
                            return <IonItem key={'location-' + index}>
                                <IonAvatar slot="start">
                                    <S3Image name={l.name + ' image'} fileName={l.image}/>
                                </IonAvatar>
                                <IonLabel>{l.name}</IonLabel>
                                <IonButton

                                    color={'secondary'}
                                    href={'/locations/' + l.id}
                                    style={{marginRight: 15}}
                                >
                                    <FontAwesomeIcon icon={faEye}/>
                                </IonButton>
                                <Button
                                    loading={l.id === deleting}
                                    onClick={() =>
                                        present([
                                            {
                                                text: 'Elimina',
                                                role: 'destructive',
                                                data: {
                                                    type: 'delete'
                                                },
                                                handler: () => {
                                                    presentAlert({
                                                        header: 'Sei veramente sicuro di voler elminare questo locale? (' + l.name + ')',
                                                        buttons: [
                                                            {
                                                                text: 'No, annulla',
                                                                role: 'cancel'
                                                            },
                                                            {
                                                                text: 'Si, elimina',
                                                                role: 'confirm',
                                                                handler: () => {
                                                                    deleteLocation(l)
                                                                }
                                                            }
                                                        ],
                                                    })
                                                }
                                            },
                                            {
                                                text: 'Modifica',
                                                handler: () => {
                                                    setName(l.name);
                                                    setDescription(l.description ?? '');
                                                    setCity(l.city ?? '');
                                                    setZip(l.zip ?? '');
                                                    setAddress(l.address ?? '');
                                                    setState(l.state ?? '')
                                                    setDefaultPhoto(l.image);
                                                    setSelectedModel(l);
                                                    modal.current?.present();
                                                }
                                            },
                                            {
                                                text: 'Annulla'
                                            }
                                        ], 'Locale: ' + l.name)
                                    }
                                    color={'tertiary'}>
                                    <FontAwesomeIcon
                                        icon={faWrench}
                                    />
                                </Button>
                            </IonItem>
                        })
                    }
                </IonList>


                <IonModal
                    id="example-modal"
                    ref={modal}
                    trigger="open-modal"
                    enterAnimation={enterAnimation}
                    leaveAnimation={leaveAnimation}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => {
                                    modal.current?.dismiss();
                                    resetForm();
                                }}>Annulla</IonButton>
                            </IonButtons>
                            <IonTitle style={{textAlign: 'center'}}>Aggiungi un nuovo locale</IonTitle>
                            <IonButtons slot="end">
                                {selectedModel ?
                                    <Button color={'primary'} fill={'outline'} strong={true}
                                            loading={saving}
                                            onClick={() => update(selectedModel)}>
                                        Modifica
                                    </Button> :
                                    <Button color={'primary'} fill={'outline'} strong={true}
                                            loading={saving}
                                            onClick={() => create()}>
                                        Crea
                                    </Button>
                                }
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating">Nome</IonLabel>
                                <IonInput value={name}
                                          type={'text'}
                                          onIonChange={(e) => setName(e.detail.value!)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Descrizione</IonLabel>
                                <IonTextarea value={description}
                                             rows={5}
                                             onIonChange={(e) => setDescription(e.detail.value!)}/>
                            </IonItem>

                            <IonItem style={{padding: 15}} lines={'none'}>
                                {!photo ?
                                    (
                                        defaultPhoto === 'placeholder' ?
                                            <IonImg src={defaultPhoto} style={{width: 200, height: 120}}></IonImg> :
                                            <S3Image name={defaultPhoto} fileName={defaultPhoto}
                                                     style={{width: 200, height: 120}}/>
                                    ) :
                                    <IonImg src={photo} style={{width: 200, height: 120}}></IonImg>
                                }
                                <IonButton style={{marginLeft: 50}}
                                           color={'dark'} fill={'outline'}
                                           expand="full" onClick={() => chooseImage()}>
                                    Seleziona immagine
                                </IonButton>
                            </IonItem>


                            <IonItemDivider>Indirizzo</IonItemDivider>
                            <IonItem>
                                <IonLabel position="floating">Via/Piazza</IonLabel>
                                <IonInput value={address}
                                          type={'text'}
                                          onIonChange={(e) => setAddress(e.detail.value!)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Codice postale</IonLabel>
                                <IonInput value={zip}
                                          type={'text'}
                                          onIonChange={(e) => setZip(e.detail.value!)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Città</IonLabel>
                                <IonInput value={city}
                                          type={'text'}
                                          onIonChange={(e) => setCity(e.detail.value!)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Provicia</IonLabel>
                                <IonInput value={state}
                                          type={'text'}
                                          onIonChange={(e) => setState(e.detail.value!)}/>
                            </IonItem>
                            <IonItem lines={'none'}>
                                <IonLabel position="floating">Paese</IonLabel>
                                <IonInput value={country}
                                          readonly
                                          type={'text'}
                                          onIonChange={(e) => setCountry(e.detail.value!)}/>
                            </IonItem>


                            <IonItem lines={'none'}>
                                <IonLabel>
                                    <span>Latitude: {parseFloat(latitude).toFixed(4)}</span><br/>
                                    <span>Longitude: {parseFloat(longitude).toFixed(4)}</span>
                                    <br/>
                                    <IonButton expand={'block'} onClick={() => setIsGmapOpen(true)}>
                                        <FontAwesomeIcon icon={faMapMarked} style={{marginRight: 15}}/> Vedi con
                                        Gmap
                                    </IonButton>
                                    <div style={{fontSize: '80%', textAlign: 'center'}}>Clicca sul bottone per impostare le coordinate</div>
                                </IonLabel>
                            </IonItem>


                            <IonItemDivider>Orari</IonItemDivider>
                            <CalendarPage calendar={calendar} setCalendar={setCalendar}/>
                        </IonList>
                    </IonContent>
                </IonModal>


                <IonModal
                    isOpen={isGmapOpen}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => {
                                    setIsGmapOpen(false);
                                }}>Annulla</IonButton>
                            </IonButtons>
                            <IonTitle style={{textAlign: 'center'}}>Modifica posizione</IonTitle>
                            <IonButtons slot="end">
                                <Button color={'primary'} fill={'outline'} strong={true}
                                        onClick={() => {
                                            setIsGmapOpen(false);
                                        }}>
                                    Ok
                                </Button>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <capacitor-google-map ref={mapRef} style={{
                            display: 'inline-block',
                            width: '100%',
                            height: '93%'
                        }}></capacitor-google-map>
                    </IonContent>
                </IonModal>

            </>
            }
        </IonContent>
        <Footer/>
    </IonPage>
}

export default Locations;