import React from 'react';
import {IonButton} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {Color} from "@ionic/core";

const Button: React.FC<{
    expand?: "block" | "full" | undefined,
    fill?: "clear" | "outline" | "solid" | "default" | undefined,
    loading?: boolean,
    disabled?: boolean,
    onClick?: any,
    style?: any,
    color?: Color,
    strong?: boolean
}> = (
    {
        expand ,
        fill,
        loading = false,
        disabled = false,
        onClick = null,
        style = null,
        color = undefined,
        strong = false,
        children
    }
) => {

    return <IonButton
        disabled={disabled || loading}
        color={color}
        onClick={onClick}
        strong={strong}
        fill={fill}
        expand={expand}
        style={style}>
        {loading && <FontAwesomeIcon icon={faSpinner} pulse spin style={{marginRight: 10}}/>}
        {children}
    </IonButton>
}

export default Button;