import React from 'react';
import {IonButton, IonHeader, IonMenuToggle, IonTitle, IonToolbar} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";

const Navbar: React.FC<{ title?: string }> = (
    {
        title
    }) => {
    return <IonHeader collapse="fade" translucent={true} className={'main-header'}>
        <IonToolbar>
            <div style={{display: 'flex'}}>
                <IonMenuToggle>
                    <IonButton fill={'clear'}>
                        <FontAwesomeIcon icon={faBars} color={'black'}/>
                    </IonButton>
                </IonMenuToggle>
                <IonTitle>{title ?? 'Table Booking'}</IonTitle>
            </div>
        </IonToolbar>
    </IonHeader>
}

export default Navbar;
