import React from 'react';
import Navbar from "../shared/Navbar";
import {
    IonCard,
    IonCardContent,
    IonCardHeader, IonCardTitle,
    IonContent,
    IonPage,
} from "@ionic/react";
import Footer from "../shared/Footer";

const TermsAndConditions: React.FC = () => {


    return <IonPage>
        <Navbar title={'Termini e condizioni'}/>
        <IonContent>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        Termini e Condizioni di Stridegate - Table Booking
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <div className="iub_content legal_pp">
                        <div className="iub_header">
                                        <p>Questi Termini disciplinano</p>
                            <ul>
                                <li>l’utilizzo di questa Applicazione e</li>
                                <li>qualsiasi altro Accordo o rapporto giuridico con il Titolare</li>
                            </ul>
                            <p>in maniera vincolante. Le espressioni con l’iniziale maiuscola sono definite nella
                                relativa
                                sezione di questo documento.</p>
                            <br/>
                            <p>L’Utente è pregato di leggere attentamente questo documento.</p>
                        </div>


                        <p>Il soggetto responsabile di questa Applicazione è:</p>

                        <br/>
                        <div className="one_line_col">
                            <h2 id="owner_of_the_data">
                                Titolare del Trattamento dei Dati
                            </h2>
                            <p>Gianluca Bragazzi, Via Borgonuovo 17, Bologna, 4015 BO
                                Italia</p>
                            <p><strong>Indirizzo email del Titolare:</strong> gianluca.bragazzi@stridegate.com</p>
                        </div>
                        <br/>
                        <br/>

                        <hr/>

                        <h2 id="condizioni-d-uso">CONDIZIONI D'USO</h2><p>Salvo ove diversamente specificato, le
                        condizioni
                        d’uso di questo Sito Web esposte in questa sezione hanno validità generale.</p>

                        <p>Ulteriori condizioni d’uso o d’accesso applicabili in particolari situazioni sono
                            espressamente
                            indicate in questo documento.</p>

                        <p>Utilizzando questo Sito Web l’Utente dichiara di soddisfare i seguenti requisiti:</p>
                        <h3 id="contenuti-su-this_application">Contenuti su questo Sito Web</h3>
                        <p>Salvo ove diversamente specificato o chiaramente riconoscibile, tutti i contenuti disponibili
                            su
                            questo Sito Web sono di proprietà di o forniti dal Titolare o dei/dai suoi licenzianti.</p>

                        <p>Il Titolare adotta la massima cura affinché il contenuto disponibile su questo Sito Web non
                            violi
                            la normativa applicabile o diritti di terze parti. Tuttavia, non sempre è possibile
                            raggiungere
                            tale risultato.<br/>
                            In tali casi, senza alcun pregiudizio ai diritti ed alle pretese legalmente esercitabili,
                            gli Utenti sono pregati di indirizzare i relativi reclami ai recapiti specificati in questo
                            documento.</p>
                        <h3 id="accesso-a-risorse-esterne">Accesso a risorse esterne</h3>
                        <p>Tramite questo Sito Web gli Utenti potrebbero avere accesso a risorse fornite da terzi. Gli
                            Utenti riconoscono ed accettano che il Titolare non ha alcun controllo su tali risorse e che
                            pertanto non risponde del loro contenuto e della loro disponibilità.</p>

                        <p>Le condizioni applicabili alle risorse fornite da terzi, ivi incluse quelle applicabili a
                            eventuali concessioni di diritti su contenuti, sono determinate dagli stessi terzi e
                            regolate
                            nei relativi termini e condizioni o, in loro assenza, dalla legge.</p>
                        <h3 id="uso-ammesso">Uso ammesso</h3>
                        <p>Questo Sito Web ed il Servizio possono essere utilizzati solo per gli scopi per i quali sono
                            offerti, secondo questi Termini ed ai sensi della legge applicabile.</p>

                        <p>È responsabilità esclusiva dell’Utente di far sì che l’uso di questo Sito Web e/o del
                            Servizio
                            non violi la legge, i regolamenti o i diritti di terzi.</p>


                        <h2 id="disposizioni-comuni">Disposizioni comuni</h2><h3 id="nessuna-rinuncia-implicita">Nessuna
                        rinuncia implicita</h3>
                        <p>Il mancato esercizio di diritti di legge o pretese derivanti da questi Termini da parte del
                            Titolare non costituisce rinuncia agli stessi. Nessuna rinuncia può essere considerata
                            definitiva in relazione ad uno specifico diritto o a qualsiasi altro diritto.</p>
                        <h3 id="interruzione-del-servizio">Interruzione del Servizio</h3>
                        <p>Per garantire il miglior livello di servizio possibile, il Titolare si riserva di
                            interrompere il
                            Servizio per finalità di manutenzione, aggiornamenti di sistema o per qualsiasi altra
                            modifica,
                            dandone idonea notizia agli Utenti.</p>

                        <p>Nei limiti di legge, il Titolare si riserva di sospendere o terminare completamente il
                            Servizio.
                            In caso di terminazione del Servizio, il Titolare si adopererà affinché gli Utenti possano
                            estrarre i propri Dati Personali e le informazioni secondo le disposizioni di legge.</p>

                        <p>Inoltre, il Servizio potrebbe non essere disponibile per cause che si sottraggono al
                            ragionevole
                            controllo del Titolare, quali cause di forza maggiore (p. es. scioperi, malfunzionamenti
                            infrastrutturali, blackout etc.).</p>
                        <h3 id="rivendita-del-servizio">Rivendita del Servizio</h3>
                        <p>Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o
                            sfruttare
                            questo Sito Web o il Servizio in toto o in parte senza previo consenso scritto del Titolare,
                            espresso direttamente o attraverso un legittimo programma di rivendite.</p>
                        <h3 id="proprieta-intellettuale">Proprietà intellettuale</h3>
                        <p>Senza pregiudizio ad alcuna previsione più specifica contenuta nei Termini, i diritti di
                            proprietà intellettuale ed industriale, quali ad esempio diritti d’autore, marchi, brevetti
                            e
                            modelli relativi a questo Sito Web sono detenuti in via esclusiva dal Titolare o dai suoi
                            licenzianti e sono tutelati ai sensi della normativa e dei trattati internazionali
                            applicabili
                            alla proprietà intellettuale.</p>

                        <p>Tutti i marchi – denominativi o figurativi – ed ogni altro segno distintivo, ditta, marchio
                            di
                            servizio, illustrazione, immagine o logo che appaiono in collegamento con questo Sito Web
                            sono e
                            restano di esclusiva proprietà del Titolare o dei suoi licenzianti e sono tutelati ai sensi
                            della normativa e dei trattati internazionali applicabili alla proprietà intellettuale.</p>
                        <h3 id="modifiche-dei-termini">Modifiche dei Termini</h3>
                        <p>Il Titolare si riserva il diritto di modificare i Termini in ogni momento. In tal caso, il
                            Titolare darà opportuna notizia delle modifiche agli Utenti.</p>

                        <p>Le modifiche avranno effetti sul rapporto con l’Utente solo per il futuro.</p>

                        <p>L’utilizzo continuato del Servizio implica l’accettazione dell’Utente dei Termini aggiornati.
                            Se
                            l’Utente non desidera accettare le modifiche, deve cessare l’utilizzo del Servizio. La
                            mancata
                            accettazione dei Termini aggiornati potrebbe comportare la facoltà di ciascuna parte di
                            recedere
                            dall’Accordo.</p>

                        <p>La versione precedente applicabile continua a disciplinare il rapporto fino all’accettazione
                            dell’Utente. Tale versione può essere richiesta al Titolare.</p>
                        <h3 id="cessione-del-contratto">Cessione del contratto</h3>
                        <p>Il Titolare si riserva il diritto di trasferire, cedere, disporre di, novare o appaltare
                            singoli
                            o tutti i diritti e le obbligazioni secondo questi Termini, avendo riguardo per gli
                            interessi
                            legittimi degli Utenti.</p>

                        <p>Si applicano le disposizioni relative alla modifica di questi Termini.</p>

                        <p>L’Utente non è autorizzato a cedere o trasferire i propri diritti e le proprie obbligazioni
                            secondo i Termini senza il consenso scritto del Titolare.</p>
                        <h3 id="contatti">Contatti</h3>
                        <p>Tutte le comunicazioni inerenti all’uso di questo Sito Web devono essere inviate ai recapiti
                            indicati in questo documento.</p>
                        <h3 id="clausola-di-salvaguardia">Clausola di salvaguardia</h3>
                        <p>Qualora alcuna delle disposizioni di questi Termini dovesse essere o divenire nulla o
                            inefficace
                            ai sensi della legge applicabile, la nullità o inefficacia di tale disposizione non provoca
                            inefficacia delle restanti previsioni, che permangono pertanto valide ed efficaci.</p>


                        <div className="one_line_col">
                            <div className="box_primary box_10 definitions expand expanded">
                                <h2 className="expand-click w_icon_24 icon_ribbon">
                                    Definizioni e riferimenti legali
                                </h2>
                                <div className="expand-content">
                                    <h4>Questo Sito Web (o questa Applicazione)</h4>
                                    <p>La struttura che consente la fornitura del Servizio.</p>
                                    <h4>Accordo</h4>
                                    <p>Qualsiasi rapporto legalmente vincolante o contrattuale tra il Titolare e
                                        l’Utente
                                        disciplinato dai Termini.</p>
                                    <h4>Titolare (o Noi)</h4>
                                    <p>Indica la persona fisica o giuridica che fornisce questo Sito Web e/o offre il
                                        Servizio agli Utenti.</p>
                                    <h4>Servizio</h4>
                                    <p>Il servizio offerto tramite questo Sito Web così come descritto nei Termini e su
                                        questo Sito Web.</p>
                                    <h4>Termini</h4>
                                    <p>Tutte le condizioni applicabili all’utilizzo di questo Sito Web e/o alla
                                        fornitura
                                        del Servizio così come descritti in questo documento nonché in qualsiasi altro
                                        documento o accordo ad esso collegato, nella versione rispettivamente più
                                        aggiornata.</p>
                                    <h4>Utente (o Tu)</h4>
                                    <p>Indica qualsiasi persona fisica che utilizzi questo Sito Web.</p>
                                </div>
                            </div>
                        </div>
                        <div className="iub_footer">

                            <p>
                                Ultima modifica: 3 agosto 2022
                            </p>

                        </div>

                    </div>
                </IonCardContent>
            </IonCard>
        </IonContent>
        <Footer/>
    </IonPage>
}

export default TermsAndConditions;