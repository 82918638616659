import React from 'react'
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";
import {IonContent, IonImg, IonPage,} from "@ionic/react";

const NotFound: React.FC = () => {
    return <IonPage>
        <Navbar title={'404 Non trovata'}/>
        <IonContent fullscreen={true}>
            <IonImg src={'assets/page404.png'} style={{width: 350, margin: 'auto'}}/>
        </IonContent>
        <Footer/>
    </IonPage>
}

export default NotFound;