import React, {Dispatch, useEffect, useState} from 'react';
import {
    createAnimation,
    DatetimeCustomEvent,
    IonButton,
    IonButtons,
    IonChip,
    IonCol,
    IonContent,
    IonDatetime,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    useIonActionSheet,
    useIonAlert
} from "@ionic/react";
import {now} from "../../functions/now";
import {Booking} from "../../interfaces/Booking";
import {Table} from "../../interfaces/Table";
import {Location} from "../../interfaces/Location";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faInfoCircle, faPlus, faWrench} from "@fortawesome/pro-solid-svg-icons";
import {RoomService} from "../../services/RoomService";
import {useParams} from "react-router-dom";
import {Room} from "../../interfaces/Room";
import BookingWizard from "../BookingWizard";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {closeModal, openModal} from "../../store/actions/IframeActions";
import {hours} from "../../functions/hours";
import {setMessageAction} from "../../store/actions/MessageActions";
import {BookingService} from "../../services/BookingService";


const Bookings: React.FC<{ location: Location, setLocation: any }> = (
    {
        location,
        setLocation
    }) => {

    const dispatch: Dispatch<any> = useDispatch();
    const {id}: any = useParams();
    const [presentAlert] = useIonAlert();
    const [present] = useIonActionSheet();
    const [room, setRoom] = useState<Room>()

    const [seats, setSeats] = useState<number>(0)
    const [date, setDate] = useState<any>(now());
    const [ionDate, setIonDate] = useState<any>(null);
    const [bookings, setBookings] = useState<Booking[]>([])
    const [retrievingBookings, setRetrievingBookings] = useState<boolean>(false);
    const [buggedIonDatetimeMultipleFire, setBuggedIonDatetimeMultipleFire] = useState<boolean>(false)
    const {
        isModalOpen
    } = useSelector<RootState, any>((state: RootState) => state.iframeReducer);

    const [start, setStart] = useState<number>()
    const [end, setEnd] = useState<number>()


    useEffect(() => {
        if (!buggedIonDatetimeMultipleFire && start && end && date) {

            if (end < start) {
                dispatch(setMessageAction('L\'orario di fine deve essere maggiore di quello d\'inizio', 'warning'))
                return;
            }

            setRetrievingBookings(true)
            setBuggedIonDatetimeMultipleFire(true);
            RoomService.checkAvailability(date, id, undefined, start, end)
                .then((bl: Booking[]) => {
                    setBookings(bl);

                })
                .finally(() => {
                    setBuggedIonDatetimeMultipleFire(false)
                    setRetrievingBookings(false)
                })
        }
    }, [date, start, end])


    useEffect(() => {
        let tot = 0;
        bookings.forEach((b: Booking) => {
            return tot = tot + b.seats;
        })
        setSeats(tot);
    }, [bookings])


    const deleteBooking = async (b: Booking) => {
        BookingService.delete(b.id)
            .then(() => {
                setBookings([...bookings.filter((bb: Booking) => bb.id !== b.id)])
                dispatch(setMessageAction('Prenotazione eliminata', 'success'))
            })
    }

    const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;

        const backdropAnimation = createAnimation()
            .addElement(root?.querySelector('ion-backdrop')!)
            .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
            .addElement(root?.querySelector('.modal-wrapper')!)
            .keyframes([
                {offset: 0, opacity: '0', transform: 'scale(0)'},
                {offset: 1, opacity: '0.99', transform: 'scale(1)'},
            ]);

        return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: HTMLElement) => {
        return enterAnimation(baseEl).direction('reverse');
    };


    return <div style={{padding: '1em'}}>

        <IonDatetime hourCycle={'h12'}
                     presentation={'date'}
                     showDefaultTimeLabel={false}
                     value={ionDate}
                     defaultValue={now(1)}
                     onIonChange={(e: DatetimeCustomEvent) => {
                         if (e.detail && e.detail.value) {
                             setDate(e.detail.value)
                             setIonDate(e.detail.value)
                         }
                     }}
                     locale='it-IT' size={'cover'}>
        </IonDatetime>

        <IonGrid>
            <IonRow className="ion-align-items-center">
                <IonCol className="ion-align-self-center" size="6">
                    <IonSelect placeholder={'Ora inizio'} value={start} onIonChange={(e) => setStart(e.detail.value)}>
                        {hours().map((n: number) =>
                            <IonSelectOption value={n} key={'1k-' + n}>{n}</IonSelectOption>
                        )}
                    </IonSelect>
                </IonCol>
                <IonCol className="ion-align-self-center" size="6">
                    <IonSelect placeholder={'Ora fine'} value={end} onIonChange={(e) => setEnd(e.detail.value)}>
                        {hours().map((n: number) =>
                            <IonSelectOption value={n} key={'2k-' + n}>{n}</IonSelectOption>
                        )}
                    </IonSelect>
                </IonCol>
            </IonRow>
        </IonGrid>


        <br/>
        {(end && start && end > start) && <>
            Posti totali: {location.total_seats ?? 0}
            <br/> Nel range selezionato: {(location.total_seats ?? 0) * (end - start)}
            <FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 16}} id="click-trigger"/>
            <IonPopover trigger="click-trigger" triggerAction="hover">
                <IonContent class="ion-padding">Il totale dei posti è calcolato moltiplicando il numero di posti effettivo per l'intervallo di ore selezionato</IonContent>
            </IonPopover>
            <br/>
            Posti occupati nel range: {seats}
            <br/>
            Posti liberi nel range: {(location.total_seats ?? 0) * (end - start) - seats}
        </>
        }
        {retrievingBookings ? <IonLoading
                cssClass='my-custom-class'
                isOpen={retrievingBookings}
                message={'Recupero prenotazioni...'}
            /> :
            <IonList>
                {
                    bookings?.map((b: Booking, index: number) => {
                        const tables = JSON.parse(b.tables);
                        return <IonItem key={'booking-' + index}>
                            <IonLabel>
                                Numero: {b.id} - {b.room?.name}
                                <br/>
                                Posti occupati: {b.seats}
                                <br/>
                                {tables.map((t: Table) => <IonChip key={t.id}>Tavolo: {t.id}</IonChip>)}
                            </IonLabel>
                            <IonButton
                                onClick={() =>
                                    present([
                                        {
                                            text: 'Elimina',
                                            role: 'destructive',
                                            data: {
                                                type: 'delete'
                                            },
                                            handler: () => {
                                                presentAlert({
                                                    header: 'Sei veramente sicuro di voler elminare questa prenotazione? (' + b.id + ')',
                                                    buttons: [
                                                        {
                                                            text: 'No, annulla',
                                                            role: 'cancel'
                                                        },
                                                        {
                                                            text: 'Si, elimina',
                                                            role: 'confirm',
                                                            handler: () => {
                                                                deleteBooking(b)
                                                            }
                                                        }
                                                    ],
                                                })
                                            }
                                        },
                                        {
                                            text: 'Annulla'
                                        }
                                    ], 'Prenotazione: ' + b.id)
                                }
                                color={'tertiary'}>
                                <FontAwesomeIcon
                                    icon={faWrench}
                                />
                            </IonButton>
                            <IonButton onClick={() => 'cartina tavoli'}>
                                <FontAwesomeIcon
                                    icon={faEye}
                                />
                            </IonButton>
                        </IonItem>
                    })
                }
            </IonList>}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => dispatch(openModal())} color={'secondary'}>
                <FontAwesomeIcon icon={faPlus}/>
            </IonFabButton>
        </IonFab>

        <IonModal
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            isOpen={isModalOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Nuova prenotazione</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => dispatch(closeModal())}>Annulla</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonSelect
                    value={room}
                    placeholder={'Seleziona una stanza'}
                    onIonChange={e => setRoom(e.detail.value)}>
                    {location.rooms.map((r: Room) => <IonSelectOption key={r.id} value={r}>{r.name}</IonSelectOption>)}
                </IonSelect>
                {room &&
                    <BookingWizard calendars={location.calendars}
                                   room={room}
                                   owner={true}
                                   roomId={room.id}
                                   id={id}
                                   location={location}/>
                }
            </IonContent>
        </IonModal>

    </div>


}

export default Bookings;