import React, {Dispatch, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import LoginFunction from "../functions/LoginFunction";
import {RootState, LangContextS} from "../../store";
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSlide,
    IonSlides,
    IonText,
} from "@ionic/react";
import Form from "../../components/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faEye, faEyeSlash,
    faSealQuestion,
    faUserPlus
} from "@fortawesome/pro-solid-svg-icons";
import Button from "../../components/Button";
import {setStatusAction} from "../../store/actions/AuthenticationActions";
import {IonSlidesCustomEvent} from '@ionic/core/dist/types/components';
import {setMessageAction} from "../../store/actions/MessageActions";
import Navbar from "../../shared/Navbar";
import Footer from "../../shared/Footer";


const slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false
};


const SignIn: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const {status, email} = useSelector<RootState, any>((state: RootState) => state.authReducer);
    const [signInFunction, setSignInFunction] = useState<LoginFunction | undefined>();
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);

    const [emailInput, setEmailInput] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const {lang} = useSelector<RootState, any>((state: RootState) => state.langReducer);
    const LangMapper: any = React.useContext(LangContextS);

    const [slider, setSlider] = useState<any>();


    const [code, setCode] = useState<string>('');


    useEffect(() => {
        if (LangMapper && lang) {
            const sif = new LoginFunction(dispatch, setSendingEmail, setSubmitInProgress, setInvalidCredentials);
            setSignInFunction(sif);
        }
    }, [dispatch, LangMapper, lang]);


    useEffect(() => {
        switch (slider && status) {
            case 'SIGN_IN':
                slider?.slideTo(0)
                break;
            case 'CONFIRM_USER_REGISTRATION':
                slider?.slideTo(1)
                break;
        }
    }, [status, slider])

    const login = () => {
        signInFunction?.handleSignIn(emailInput, password)
    }

    const confirmAccount = () => {
        if (emailInput === '') {
            signInFunction?.handleConfirmUser(email, code);
        } else {
            signInFunction?.handleConfirmUser(emailInput, code);
        }
    }

    const sendCodeAgain = () => {
        if (!emailInput) {
            dispatch(setMessageAction('Inserisci un email valida', 'danger'))
            return;
        }
        signInFunction?.resendEmail(emailInput)
    }


    return <IonPage>
        <Navbar title={'Accedi'}/>
        <IonContent>
            <IonCard style={{maxWidth: 450, margin: '10px auto'}}>
                <IonCardContent>
                    <IonText>
                        <h2 style={{margin: '1em 0 1.5em 0'}}>
                            {LangMapper[lang].form?.registerTitle}
                        </h2>
                    </IonText>
                    <IonText>
                        <p style={{marginBottom: 30}}>
                            {LangMapper[lang].form?.registerHint}
                        </p>
                    </IonText>

                    <IonSegment value={status} style={{marginBottom: 16}}>
                        <IonSegmentButton value="SIGN_IN" onClick={() => dispatch(setStatusAction('SIGN_IN'))}>
                            <IonLabel>Accedi</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="CONFIRM_USER_REGISTRATION"
                                          disabled={status !== 'CONFIRM_USER_REGISTRATION'}>
                            <IonLabel>Conferma</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>


                    <IonSlides
                        pager={false}
                        options={slideOpts}
                        onIonSlidesDidLoad={(e: IonSlidesCustomEvent<void>) => setSlider(e.target)}>

                        <IonSlide>
                            <Form style={{width: '100%'}}>
                                <IonList>
                                    <IonItem>
                                        <IonLabel position="floating">
                                            {LangMapper[lang].login?.emailPlaceholder}
                                        </IonLabel>
                                        <IonInput
                                            name={'email'}
                                            value={emailInput}
                                            autocomplete={'email'}
                                            type={'email'}
                                            inputMode={'email'}
                                            placeholder={'mario.rossi@libero.it'}
                                            onIonChange={(e: any) => setEmailInput(e.detail.value!)}/>
                                    </IonItem>
                                    {invalidCredentials && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{invalidCredentials}</small>
                                        </IonText>
                                    )}


                                    <IonItem>
                                        <IonLabel position={'floating'}>
                                            {LangMapper[lang].login?.passwordPlaceholder}
                                        </IonLabel>

                                        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                            <IonInput
                                                name={'password'}
                                                style={{flex: 1}}
                                                value={password}
                                                autocomplete={'current-password'}
                                                type={showPassword ? 'text' : 'password'}
                                                clearOnEdit={false}
                                                onIonChange={(e: any) => setPassword(e.detail.value!)}/>
                                            <FontAwesomeIcon
                                                style={{marginLeft: 15, cursor: "pointer"}}
                                                id="cover-trigger"
                                                onClick={() => setShowPassword(!showPassword)}
                                                icon={!showPassword ? faEye : faEyeSlash}/>
                                        </div>
                                    </IonItem>

                                    <Button
                                        style={{marginTop: 24}}
                                        expand={'block'}
                                        onClick={() => login()}
                                        loading={submitInProgress}>
                                        {LangMapper[lang].login?.signIn}
                                    </Button>
                                </IonList>
                            </Form>
                        </IonSlide>


                        <IonSlide>
                            <Form style={{width: '100%'}}>
                                <IonText>
                                    <p>
                                        Il tuo account è stato creato, per confermarlo inserisci
                                        il codice che ti abbiamo inviato all'email che hai usato per registrati
                                    </p>
                                </IonText>
                                <IonList>
                                    <IonItem>
                                        <IonLabel position={'floating'}>
                                            Codice di conferma
                                        </IonLabel>
                                        <IonInput
                                            style={{flex: 1}}
                                            value={code}
                                            autocomplete={'one-time-code'}
                                            clearOnEdit={false}
                                            onIonChange={(e: any) => setCode(e.detail.value!)}/>

                                    </IonItem>

                                    <Button
                                        style={{marginTop: 24, marginBottom: 24}}
                                        expand={'block'}
                                        onClick={() => confirmAccount()}
                                        loading={submitInProgress}>
                                        Conferma account
                                    </Button>


                                    <IonText>
                                        <p>
                                            Non hai ricevuto la mail? Controlla nello spam!.
                                        </p>
                                        <p>
                                            Se il tuo server di posta elettronica è particolarmente lento aspetta un
                                            paio di minuti prima di chiedere un nuovo codice:
                                            il nuovo codice fa scadere automaticamente il codice precedente.
                                        </p>
                                    </IonText>

                                    <Button
                                        fill={'clear'}
                                        style={{marginTop: 24}}
                                        expand={'block'}
                                        onClick={() => sendCodeAgain()}
                                        loading={sendingEmail}>
                                        Chiedi nuovo codice
                                    </Button>

                                </IonList>
                            </Form>
                        </IonSlide>

                    </IonSlides>


                    <IonRow style={{marginTop: 36}}>
                        {status === 'SIGN_IN' ?
                            <IonCol style={{textAlign: 'center', cursor: 'pointer'}}
                                    onClick={() => dispatch(setStatusAction('SIGN_UP'))}
                            >
                                <FontAwesomeIcon
                                    icon={faUserPlus}/> Non hai un account?
                            </IonCol> :
                            <IonCol style={{textAlign: 'center', cursor: 'pointer'}}
                                    onClick={() => dispatch(setStatusAction('SIGN_IN'))}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeft}/> Torna alla login
                            </IonCol>
                        }

                        <IonCol style={{textAlign: 'center', cursor: 'pointer'}}
                                onClick={() => dispatch(setStatusAction('ASK_RESET_PW'))}
                        >
                            <FontAwesomeIcon
                                icon={faSealQuestion}/> Password dimenticata?
                        </IonCol>
                    </IonRow>
                </IonCardContent>

            </IonCard>
            <div style={{marginTop: 300}}>
                [Var: {process.env.REACT_APP_URL}]
            </div>
        </IonContent>
        <Footer/>
    </IonPage>
};

export default SignIn;
