import React from "react";

const loginIt = {
    emailConfirmed: 'La tua email è stata confermata',
    invalidEmail: 'Inserisci una email valida',
    wrongCode: 'Il codice non è valido',
    codeSent: 'Il codice di recupero è stato inviato, controlla la tua casella di posta',
    genericError: 'Si è verificato un errore, riprova oppure contatta il nostro supporto',
    wrongCredentials: 'Credenziali errate',
    passwordUpdated: 'Password aggiornata',
    signUp: 'Registrati',
    signIn: 'Login',
    emailPlaceholder: 'Inserisci la tua email',
    passwordPlaceholder: 'Password',
    passwordConfirmPlaceholder: 'Conferma password'
}
export default loginIt;