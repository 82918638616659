import React, {Dispatch, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setStatusAction} from "../../store/actions/AuthenticationActions";
import RegisterFunction from "../functions/RegisterFunction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle, faEye, faEyeSlash,
    faInfoCircle,
    faRightToBracket,
} from "@fortawesome/pro-solid-svg-icons";
import {RootState, LangContextS} from '../../store';
import {
    IonCard,
    IonCardContent,
    IonCardHeader, IonCardTitle,
    IonCol, IonContent,
    IonInput,
    IonItem, IonLabel,
    IonList, IonPage, IonPopover, IonProgressBar,
    IonRow,
    IonText,
} from "@ionic/react";
import Form from "../../components/Form";
import Button from "../../components/Button";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import Navbar from "../../shared/Navbar";
import Footer from "../../shared/Footer";

const SignUp: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
    const [signUpFunction, setSignUpFunction] = useState<RegisterFunction | undefined>();
    const [errorCreatingUser, setErrorCreatingUser] = useState<boolean>(false);

    const LangMapper: any = React.useContext(LangContextS);
    const {lang} = useSelector<RootState, any>((state: RootState) => state.langReducer);

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>();

    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<string>();
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [percentage, setPercentage] = useState<number>(0);
    const [validDigit, setValidDigit] = useState<boolean>(false);
    const [validUppercase, setValidUppercase] = useState<boolean>(false);
    const [validLowercase, setValidLowercase] = useState<boolean>(false);
    const [validSpecialCharacters, setValidSpecialCharacters] = useState<boolean>(false);
    const [validLen, setValidLen] = useState<boolean>(false);

    const [validPassword, setValidPassword] = useState<boolean>(false);

    useEffect(() => {
        if (dispatch) {
            const sif = new RegisterFunction(setSubmitInProgress, setErrorCreatingUser, dispatch);
            setSignUpFunction(sif);
        }
    }, [dispatch]);


    const submit = () => {
        setEmailError(undefined);
        setPasswordError(undefined);

        // validate email
        const emailRegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if (!emailRegExp.test(email)) {
            setEmailError(LangMapper[lang].login?.invalidEmail);
            return;
        }

        // validate password
        if (!validPassword) {
            setPasswordError('Password non valida: Deve contenere almeno 9 caratteri, un numero, una lettera minuscola, una lettera maiuscola, un carattere speciale')
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Le due password non coincidono')
            return;
        }


        signUpFunction?.handleSignUp(email, password);
    }

    useEffect(() => {
        let newPercentage = 0;

        if (password && password.length > 0) {
            const newValidDigit = new RegExp(/\d/).test(password);
            const newValidUppercase = new RegExp(/[A-Z]/).test(password);
            const newValidLowercase = new RegExp(/[a-z]/).test(password);
            const newValidSpecialCharacters = new RegExp(/\W/).test(password);
            const newValidLen = password.length >= 9 && password.length <= 99;

            setValidDigit(newValidDigit); // async
            setValidUppercase(newValidUppercase);  // async
            setValidLowercase(newValidLowercase);  // async
            setValidSpecialCharacters(newValidSpecialCharacters);  // async
            setValidLen(newValidLen);  // async

            setValidPassword(
                newValidDigit &&
                newValidUppercase &&
                newValidLowercase &&
                newValidSpecialCharacters &&
                newValidLen
            )

            newPercentage = (newValidDigit ? 0.2 : 0) +
                (newValidUppercase ? 0.2 : 0) +
                (newValidLowercase ? 0.2 : 0) +
                (newValidSpecialCharacters ? 0.2 : 0) +
                (newValidLen ? 0.2 : 0)

        } else {
            setValidDigit(false);
            setValidUppercase(false);
            setValidLowercase(false);
            setValidSpecialCharacters(false);
            setValidLen(false);
        }

        setPercentage(newPercentage); // async
    }, [password])


    return (
        <IonPage>
            <Navbar title={'Registrati'}/>
            <IonContent>
                <IonCard style={{maxWidth: 450, margin: '10px auto'}}>
                    <IonCardHeader>
                        <IonCardTitle>Crea un nuovo account gratuito</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonText>
                            <h2 style={{margin: '1em 0 1.5em 0'}}>
                                {LangMapper[lang].form?.registerTitle}
                            </h2>
                        </IonText>
                        <IonText>
                            <p style={{marginBottom: 30}}>
                                {LangMapper[lang].form?.registerHint}
                            </p>
                        </IonText>


                        <Form style={{position: 'relative', maxWidth: 450}}>
                            <IonList>
                                <IonItem>
                                    <IonLabel position="floating">
                                        {LangMapper[lang].login?.emailPlaceholder}
                                    </IonLabel>
                                    <IonInput
                                        value={email}
                                        autocomplete={'email'}
                                        type={'email'}
                                        inputMode={'email'}
                                        placeholder={'mario.rossi@libero.it'}
                                        onIonChange={(e: any) => setEmail(e.detail.value!)}/>
                                </IonItem>
                                {emailError && (
                                    <IonText color="danger" className="ion-padding-start">
                                        <small>{emailError}</small>
                                    </IonText>
                                )}

                                <IonItem>
                                    <IonLabel position={'floating'}>
                                        {LangMapper[lang].login?.passwordPlaceholder}
                                    </IonLabel>
                                    <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                        <IonInput
                                            style={{flex: 1}}
                                            value={password}
                                            autocomplete={'new-password'}
                                            type={showPassword ? 'text' : 'password'}
                                            clearOnEdit={false}
                                            onIonChange={(e: any) => setPassword(e.detail.value!)}/>
                                        <FontAwesomeIcon
                                            style={{marginLeft: 15, cursor: "pointer"}}
                                            onClick={() => setShowPassword(!showPassword)}
                                            icon={!showPassword ? faEye : faEyeSlash}/>

                                    </div>

                                    {passwordError && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{passwordError}</small>
                                        </IonText>
                                    )}

                                </IonItem>
                                {percentage > 0 &&
                                    <IonItem>
                                        <IonProgressBar value={percentage}/>
                                        <FontAwesomeIcon
                                            style={{marginLeft: 15, cursor: "pointer"}}
                                            id="cover-trigger"
                                            onClick={() => setShowPopover(true)}
                                            color={validPassword ? '#2dd36f' : 'initial'}
                                            icon={validPassword ? faCheckCircle : faInfoCircle}/>
                                        <IonPopover
                                            ref={popover}
                                            trigger="cover-trigger"
                                            side={'right'}
                                            isOpen={showPopover}
                                            onDidDismiss={() => setShowPopover(false)}>


                                            <IonList lines={'none'}>
                                                <IonItem>
                                                    <IonText color={validDigit ? 'success' : 'light'}>
                                                        <small><FontAwesomeIcon icon={faCheckCircle}
                                                                                style={{marginRight: 10}}/> Richiesto
                                                            almeno un numero</small>
                                                    </IonText>
                                                </IonItem>
                                                <IonItem>
                                                    <IonText color={validUppercase ? 'success' : 'light'}>
                                                        <small><FontAwesomeIcon icon={faCheckCircle}
                                                                                style={{marginRight: 10}}/> Richiesto un
                                                            carattere maiuscolo</small>
                                                    </IonText>
                                                </IonItem>
                                                <IonItem>
                                                    <IonText color={validLowercase ? 'success' : 'light'}>
                                                        <small><FontAwesomeIcon icon={faCheckCircle}
                                                                                style={{marginRight: 10}}/> Richiesto un
                                                            carattere minuscolo</small>
                                                    </IonText>
                                                </IonItem>
                                                <IonItem>
                                                    <IonText color={validSpecialCharacters ? 'success' : 'light'}>
                                                        <small><FontAwesomeIcon icon={faCheckCircle}
                                                                                style={{marginRight: 10}}/> Richiesto un
                                                            carattere speciale</small>
                                                    </IonText>
                                                </IonItem>
                                                <IonItem>
                                                    <IonText color={validLen ? 'success' : 'light'}>
                                                        <small><FontAwesomeIcon icon={faCheckCircle}
                                                                                style={{marginRight: 10}}/> Richiesti
                                                            almeno 9 caratteri: {password.length}</small>
                                                    </IonText>
                                                </IonItem>

                                            </IonList>


                                        </IonPopover>
                                    </IonItem>
                                }


                                <IonItem>
                                    <IonLabel position={'floating'}>
                                        {LangMapper[lang].login?.passwordConfirmPlaceholder}
                                    </IonLabel>
                                    <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                        <IonInput
                                            value={confirmPassword}
                                            autocomplete={'new-password'}
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            onIonChange={(e: any) => setConfirmPassword(e.detail.value!)}/>
                                        <FontAwesomeIcon
                                            style={{marginLeft: 15, cursor: "pointer"}}
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            icon={!showConfirmPassword ? faEye : faEyeSlash}/>
                                    </div>

                                    {confirmPasswordError && (
                                        <IonText color="danger" className="ion-padding-start">
                                            <small>{confirmPasswordError}</small>
                                        </IonText>
                                    )}
                                </IonItem>


                                {/**
                                 <EmailInput form={form}/>

                                 <PasswordInput form={form} enableHelper={true}/>

                                 <ConfirmPasswordInput form={form}/>
                                 **/}

                                <Button
                                    style={{marginTop: 24}}
                                    expand={'block'}
                                    onClick={() => submit()}
                                    loading={submitInProgress}>
                                    {LangMapper[lang].login?.signUp}
                                </Button>
                            </IonList>
                        </Form>

                        <IonRow>
                            <IonCol style={{textAlign: 'center', cursor: 'pointer'}}
                                    onClick={() => {

                                        dispatch(setStatusAction('SIGN_IN'))
                                        if (location.pathname === '/signUp') {
                                            history.push('/dashboard');
                                        }

                                    }}
                            >
                                <FontAwesomeIcon
                                    icon={faRightToBracket}/> Sei già registrato?
                            </IonCol>

                        </IonRow>

                    </IonCardContent>
                </IonCard>
            </IonContent>
            <Footer/>
        </IonPage>
    )
        ;
};

export default SignUp;
