
import {convertUTCDayToString} from "./convertUTCDayToString";
import React from "react";
import {chunk} from "./chunk";
import {Calendar} from "../interfaces/Calendar";

export const splitCalendarHours = (calendars: Calendar[], ionDate: string) => {
    const calendarDays = calendars.filter((c: Calendar) =>
        c.day_of_the_week === convertUTCDayToString(new Date(ionDate).getUTCDay())
    )!;
    if (calendarDays.length === 0) return 'Tutto il giorno';
    return calendarDays.map((c: Calendar, index: number) => <div
            key={'d-' + index}>
            dalle {c.from} alle {c.to}
        </div>
    );

}