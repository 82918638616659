import {Auth} from "aws-amplify";
import {
    setEmailAction,
    setSgUserAction,
    setStatusAction,
    setUserAction
} from "../../store/actions/AuthenticationActions";
import {CognitoUserInterface} from "../../interfaces/CognitoUserInterface";
import AuthService from "../../services/AuthService";
import {setMessageAction} from "../../store/actions/MessageActions";

class LoginFunction {
    dispatch: any;
    setSendingEmail: any;
    setSubmitInProgress: any;
    setInvalidCredentials: any;

    constructor(dispatch: any, setSendingEmail: any, setSubmitInProgress: any, setInvalidCredentials: any) {
        this.dispatch = dispatch;
        this.setSubmitInProgress = setSubmitInProgress;
        this.setSendingEmail = setSendingEmail;
        this.setInvalidCredentials = setInvalidCredentials;
    }


    handleConfirmUser = (email: string, confirmationCode: string) => {
        this.setSubmitInProgress(true);
        Auth.confirmSignUp(email, confirmationCode)
            .then(res => {
                if (res === 'SUCCESS') {
                    this.dispatch(setMessageAction('Email confermata', 'success'))
                    this.dispatch(setStatusAction('SIGN_IN'))
                }
            })
            .catch(err => {
                this.dispatch(setMessageAction('Codice di conferma errato', 'danger'))
            })
            .finally(() => {
                this.setSubmitInProgress(false);
            });
    }

    resendEmail = (email: string) => {
        if (!email) {
            this.dispatch(setMessageAction('Inserisci un email valida', 'danger'))
            return;
        }
        this.setSendingEmail(true);
        Auth.resendSignUp(email)
            .then(() => {
                this.dispatch(setMessageAction('Codice inviato, controlla la tua casella di posta elettronica', 'success'))
            })
            .catch(err => {
                console.log('error', err);
                this.dispatch(setMessageAction(err.message, 'danger'))
            })
            .finally(() => {
                setTimeout(() => {
                    this.setSendingEmail(false);
                }, 300);
            });
    }


    handleSignIn = (username: string, password: string) => {
        this.setInvalidCredentials(false);
        this.setSubmitInProgress(true);
        this.dispatch(setEmailAction(username));
        let retrievingUser = false;

        Auth.signIn({
            username,
            password,
            validationData: []
        })
            .then((user: CognitoUserInterface) => {

                switch (user.challengeName) {
                    case 'SMS_MFA':
                    case 'SOFTWARE_TOKEN_MFA':
                        this.dispatch(setUserAction({user: user, status: 'MFA'}));
                        break;
                    case 'NEW_PASSWORD_REQUIRED':
                        this.dispatch(setMessageAction('E\'necessaria una nuova password', 'success'))
                        this.dispatch(setUserAction({user: user, status: 'FORCE_CHANGE_PW'}));
                        this.dispatch(setStatusAction('FORCE_CHANGE_PW'));
                        break;
                    case 'MFA_SETUP':
                        // TODO Auth.setupTOTP(user); enabled only when application force MFA
                        break;
                    default:
                        retrievingUser = true;
                        this.dispatch(setMessageAction('Retrieving user data', 'info'))
                        AuthService.getUser()
                            .then((res) => {
                                this.dispatch(setSgUserAction(res));
                                this.dispatch(setUserAction({user: res, status: 'SIGNED_IN'}));
                            })
                            .catch((err) => {
                                console.log('pd error', err)
                                this.dispatch(setMessageAction('Errore durante la login, contatta il nostro supporto', 'danger'))
                                localStorage.clear();
                                this.dispatch(setStatusAction('SIGN_IN'));
                                // TODO LANG
                            })
                            .finally(() => {
                                this.setSubmitInProgress(false);
                            })
                }
            })
            .catch(err => {
                console.log('adsasdasd', err.code)
                switch (err.code) {
                    case 'UserNotConfirmedException':
                        this.dispatch(setStatusAction('CONFIRM_USER_REGISTRATION'));
                        break;

                    case 'PasswordResetRequiredException':
                        this.dispatch(setStatusAction('RESET_PW'));
                        break;

                    case 'NotAuthorizedException':
                    case 'UserNotFoundException':
                        this.setInvalidCredentials(true);
                        this.dispatch(setMessageAction('Credenziali errate', 'danger'))
                        break;

                    default:
                        this.setInvalidCredentials(true);
                        this.dispatch(setMessageAction(err.message, 'danger'))
                }
            })
            .finally(() => {
                if(!retrievingUser) {
                    this.setSubmitInProgress(false);
                }
            });
    }


    resetPassword = (user: CognitoUserInterface, password: string) => {
        this.setSubmitInProgress(true);
        if(!user){
            console.log('user is not a valid user:', user);
            return;
        }
        Auth.completeNewPassword(user, password)
            .then((res) => {
                this.dispatch(setMessageAction('Password modificata su successo', 'success'))
                // TODO LANG
                this.dispatch(setUserAction({user: user, status: 'SIGNED_IN'}));
            }).catch((error) => {
            console.log('error', error);
            this.dispatch(setMessageAction(error.message, 'danger'))
        }).finally(() => {
            this.setSubmitInProgress(false);
        });
    }


}

export default LoginFunction;
