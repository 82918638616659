import React, {useEffect, useState} from 'react'
import {
    IonBreadcrumb,
    IonBreadcrumbs,
    IonContent,
    IonLabel, IonLoading,
    IonPage, IonSegment, IonSegmentButton,
} from "@ionic/react";
import {useParams} from "react-router-dom";
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";
import {LocationService} from "../services/LocationService";
import {Location} from "../interfaces/Location";
import Info from "./location_components/Info";
import Bookings from "./location_components/Bookings";
import Collaborators from "./location_components/Collaborators";


const LocationPage: React.FC = () => {

    const {id}: any = useParams();
    const [location, setLocation] = useState<Location>();
    const [retrievingLocation, setRetrievingLocation] = useState<boolean>(true);
    const [segment, setSegment] = useState<string>('info')


    useEffect(() => {
        const init = async () => {
            LocationService.getLocation(id).then(setLocation)
                .finally(() => setRetrievingLocation(false))
        }
        init();
    }, [id])


    return <IonPage>
        <Navbar title={location ? location.name : 'Caricamento...'}/>
        <IonContent>
            {retrievingLocation ?
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={retrievingLocation}
                    message={'Recupero informazioni...'}
                /> :
                location ? <>
                    <IonBreadcrumbs>
                        <IonBreadcrumb href="/locations">Locations</IonBreadcrumb>
                        <IonBreadcrumb>{location.name}</IonBreadcrumb>
                    </IonBreadcrumbs>


                    <IonSegment onIonChange={e => setSegment(e.detail.value!)} value={segment}>
                        <IonSegmentButton value="info">
                            <IonLabel>Informazioni</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="bookings">
                            <IonLabel>Prenotazioni</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="collaborators">
                            <IonLabel>Collaboratori</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>

                    {segment === 'info' && <Info location={location} setLocation={setLocation}/>}

                    {segment === 'bookings' && <Bookings location={location} setLocation={setLocation}/>}

                    {segment === 'collaborators' && <Collaborators location={location} setLocation={setLocation}/>}

                </> : <>
                    Nessun risultato
                </>
            }


        </IonContent>
        <Footer/>
    </IonPage>
}

export default LocationPage;