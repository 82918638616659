import {AxiosInterceptor} from "./AxiosInterceptor";
import {AxiosInstance, AxiosResponse} from "axios";

export class BookingService {
    static listMyBookings = async (): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/myBookings`;

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };


    static create = async (data: any): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/book`;

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, data).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static delete = async (id: number): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/book/${id}`;

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.delete(url);
            });
    };



}