export const SET_LANG = 'SET_LANG';

export interface LangState {
    lang: string
}

interface setLang {
    type: typeof SET_LANG,
    payload: string
}

export type LangActionTypes = setLang;
