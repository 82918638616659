import React from "react";
import {combineReducers} from 'redux';
import {authenticationReducer} from "./reducers/AuthenticationReducer";
import {langReducer} from "./reducers/LangReducer";
import {messageReducer} from "./reducers/MessageReducer";
import {iframeReducer} from "./reducers/IframeReducer";

export interface LangContext {
}


const BaseReducer = {
    authReducer: authenticationReducer,
    langReducer: langReducer,
    messageReducer: messageReducer,
}


export const rootReducer = combineReducers({
    ...BaseReducer,
    iframeReducer: iframeReducer
});

export const LangContextS = React.createContext<LangContext | null>(null)
export const ThemeContextS = React.createContext<string | null>('antd');
export type RootState = ReturnType<typeof rootReducer>;
