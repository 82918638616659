import React, {FormEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {fabric} from "fabric";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ColorResult, SketchPicker} from 'react-color'
import {AdvancedGroup, AdvancedObject, AdvancedRect} from "../../vendors/fabricjs/Objects";
import {Room} from "../../interfaces/Room";
import {Table} from "../../interfaces/Table";
import {RoomService} from "../../services/RoomService";
import {faText} from "@fortawesome/pro-solid-svg-icons/faText";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import {faRectangle} from "@fortawesome/pro-solid-svg-icons/faRectangle";
import {
    IonButton,
    IonButtons,
    IonChip,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonText,
    IonToolbar
} from '@ionic/react';
import {faTablePicnic} from "@fortawesome/pro-solid-svg-icons/faTablePicnic";
import {Input} from "@aws-amplify/ui-react/dist/types/primitives/Input";

const TableEditor = forwardRef((props: {
    room: Room,
    setNext?: any
}, ref) => {

    const wrapper = useRef<HTMLDivElement>(null);
    const canvasEl = useRef<HTMLCanvasElement>(null)
    const canvasEl2 = useRef<HTMLCanvasElement>(null)
    const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>()
    const [shapes, setShapes] = useState<string[]>([]);

    const [color, setColor] = useState<ColorResult>();
    const [color2, setColor2] = useState<ColorResult>();
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [showPicker2, setShowPicker2] = useState<boolean>(false);

    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [activeObj, setActiveObj] = useState<Table>()

    const searchInput = React.useRef(null)


    useEffect(() => {

        const handleElement = (obj: any) => {
            if (obj.selected) {
                setActiveObj(obj.selected[0].table)
            } else {
                setActiveObj(undefined)
            }
        }


        let canvasRoom: any = null;
        if (canvasEl) {
            canvasRoom = new fabric.Canvas(canvasEl.current, {
                selection: false
            });
            canvasRoom.selection = false;

            if (props.room.structure) {
                canvasRoom.loadFromJSON(props.room.structure, function () {
                    console.log('loaded', props.room.structure);
                });
            }
        }


        let canvas: any = null;
        if (canvasEl2) {
            canvas = new fabric.Canvas(canvasEl2.current, {});
            setFabricCanvas(canvas);

            canvas.on('object:scaling', (e: any) => trigger(e, canvas))
            canvas.on('object:added', (e: any) => trigger(e, canvas))
            canvas.on('object:removed', (e: any) => trigger(e, canvas))


            // Tables
            console.log('room', props.room)
            props.room.tables?.forEach((t: Table) => {
                if (t.coordinates) {
                    fabric.util.enlivenObjects([JSON.parse(t.coordinates)], function (objects: any) {
                        objects.forEach(function (o: any) {
                            o.table = t;
                            canvas.add(o);
                        });
                    }, '');
                }
            })

            canvas.on({
                'selection:updated': handleElement,
                'selection:created': handleElement,
                'selection:cleared': handleElement
            });
        }


        return () => {
            //           updateCanvasContext(null);
            if (canvas) {
                canvas.dispose();
            }
        }
    }, [searchInput, canvasEl2, canvasEl])


    const makeId = (length: number = 8): string => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }


    const addRect = () => {
        // Initiate a Rect instance

        const newTable: Table = {
            room_id: props.room.id,
            seats: 2
        }

        const stationID = makeId();

        const rectangle = new AdvancedRect({
            width: 200,
            height: 100,
            fill: color ? color.hex : 'brown',
            id: stationID,
            name: 'rettangolo',
            centeredScaling: true,
        });

        const t = new fabric.Text('2', {
            fontFamily: 'Calibri',
            fontSize: 25,
            fill: color2 ? color2.hex : 'white',
            textAlign: 'center',
            originX: 'center',
            originY: 'center',
            left: 100,
            top: 50
        });


        const g = new AdvancedGroup([rectangle, t], {
            table: newTable,
            hasControls: true,
            rectId: stationID
        });

        fabricCanvas?.add(g);

    }


    const trigger = (e: any, cv: any) => {
        const n: any[] = [];
        cv.getObjects().forEach(function (o: any) {
            n.push(o)
        })
        setShapes(n);
    }

    const createTemp = () => {
        if (!fabricCanvas) {
            console.log('alert no fabric canvas', fabricCanvas)
            setShowCreate(false);
            return;
        }
        //   const json = fabricCanvas.toJSON();
        //   console.log('json is:', json)
        fabricCanvas.getObjects().forEach(function (o: any) {

            // NON cancellare tutti i tavoli precedenti altrimenti si rischia di perdere relazioni con prenotazioni!

            // comparare 1-1 i nuovi tavoli con i vecchi tavoli.

            console.log('object is', o)
        });
        setShowCreate(false);
    }

    const getTables = () => {
        if (!fabricCanvas) return [];

        const tables: Table[] = [];
        fabricCanvas.getObjects().map(function (o: any) {
            if (o.table) {
                const clonedTable = {...o.table};
                clonedTable.coordinates = JSON.stringify(o.toJSON());
                clonedTable.seats = o.table.seats;
                tables.push(clonedTable);
            }
        });
        return tables;
    }

    useImperativeHandle(ref, () => ({
        exposeJson() {
            return getTables();
        }
    }))

    const createPerm = async () => {
        if (!fabricCanvas) {
            console.log('alert no fabric canvas', fabricCanvas)
            setShowCreate(false);
            return;
        }

        const tables = await getTables();

        console.log('tables', tables)
        return;

        RoomService.updateTables(props.room.id, props.room.location_id, tables)
            .then(() => {
                setShowCreate(false);
            })
    }

    const acceptColor = () => {
        if (!fabricCanvas) return;
        fabricCanvas.getObjects().forEach((obj: any) => {
            obj._objects[0].set("fill", color?.hex);
        });
        fabricCanvas.renderAll();
        setShowPicker(false)
    }

    const acceptColor2 = () => {
        if (!fabricCanvas) return;

        fabricCanvas.getObjects().forEach((obj: any) => {
            obj._objects[1].set("fill", color2?.hex);
        });
        fabricCanvas.renderAll();
        setShowPicker2(false)
    }

    const changeSeats = (seats: number | '') => {
        if (!fabricCanvas) return;
        fabricCanvas.getActiveObjects().forEach((obj: any) => {
            const tableClone = {...obj.table}
            tableClone.seats = seats;
            obj.table = tableClone;
            obj._objects[1].set('text', seats ? seats.toString() : '');
            setActiveObj(tableClone)
        });
        fabricCanvas.renderAll();

    }

    // @ts-ignore
    return <>
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    margin: 'auto',
                    marginBottom: 16
                }}>

                <IonButton onClick={() => addRect()} size={'small'} color={'secondary'}>
                    <FontAwesomeIcon icon={faRectangle}/>
                </IonButton>

                <IonChip onClick={() => setShowPicker(!showPicker)}>
                    <FontAwesomeIcon icon={faTablePicnic} color={
                        (
                            color &&
                            color.hex
                        ) ?
                            color.hex :
                            'brown'
                    }/>
                    <FontAwesomeIcon icon={faChevronDown} style={{marginLeft: 10}}/>
                </IonChip>

                <IonChip onClick={() => setShowPicker2(!showPicker)}>
                    <FontAwesomeIcon icon={faText} color={
                        (
                            color2 &&
                            color2.hex
                        ) ?
                            color2.hex :
                            'white'
                    }/>
                    <FontAwesomeIcon icon={faChevronDown} style={{marginLeft: 10}}/>
                </IonChip>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1
            }}>
                <div
                    ref={wrapper}
                    style={{
                        maxWidth: 300,
                        maxHeight: 300,
                        overflow: 'hidden',
                        position: "relative"
                    }}>
                    <div

                        className={'container'}
                        style={{
                            position: "absolute",
                            right: 0,
                            bottom: 0,
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}>
                        <canvas
                            width={380} height={380}
                            ref={canvasEl2}/>
                    </div>
                    <div
                        className={'container'}
                        style={{
                            position: "relative"
                        }}>
                        <canvas
                            style={{
                                position: "relative",
                                border: "lightgrey 1px solid",
                                borderRadius: 4,
                                pointerEvents: 'none'
                            }}
                            width={300} height={300}
                            ref={canvasEl}/>
                    </div>
                </div>
            </div>
        </div>


        {activeObj &&
            <div style={{
                border: '1px solid lightgray',
                maxWidth: 300,
                margin: 'auto',
                marginTop: 12,
                borderRadius: 4
            }}>
                <IonItem lines={'none'}>
                    <IonLabel>Numero di sedie</IonLabel>
                    <IonInput
                        ref={searchInput}
                        type={'number'}
                        onIonInput={(e: any) => {
                            const value = e.target.value;
                            changeSeats(value ? parseInt(value, 10) : '')
                        }} value={activeObj.seats ?? undefined}/>
                </IonItem>
                <IonItem lines={'none'}>
                    <IonButton onClick={() => {
                        if (fabricCanvas) {
                            fabricCanvas.getActiveObjects().forEach((obj: any) => {
                                fabricCanvas.remove(obj)
                            });
                            fabricCanvas.discardActiveObject().renderAll()
                        }
                    }}>Elimina tavolo</IonButton>
                </IonItem>
            </div>}


        <IonModal isOpen={showPicker} style={{textAlign: 'center'}}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={'end'}>
                        <IonButton onClick={() => setShowPicker(false)}>Chiudi</IonButton>
                        <IonButton onClick={() => acceptColor()}>Ok</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonLabel>Colore tavolo</IonLabel>
                <div style={{margin: 'auto', width: 'fit-content', marginTop: 15}}>
                    <SketchPicker
                        color={color?.rgb}
                        onChange={setColor}/>
                </div>
            </IonContent>
        </IonModal>
        <IonModal isOpen={showPicker2} style={{textAlign: 'center'}}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={'end'}>
                        <IonButton onClick={() => setShowPicker2(false)}>Chiudi</IonButton>
                        <IonButton onClick={() => acceptColor2()}>Ok</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonLabel>Colore testo</IonLabel>
                <div style={{margin: 'auto', width: 'fit-content', marginTop: 15}}>
                    <SketchPicker
                        color={color2?.rgb}
                        onChange={setColor}/>
                </div>
            </IonContent>
        </IonModal>

        {props.setNext && <div style={{textAlign: 'right'}}>
            <IonButton onClick={() => setShowCreate(true)}>
                Completa
            </IonButton>

            <IonModal isOpen={showCreate}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot={'end'}>
                            <IonButton onClick={() => setShowCreate(false)}>Chiudi</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText>
                        Attenzione uno o più tavoli potrebbero essere stati riservati in prenotazioni
                        future.
                    </IonText><br/>
                    <IonText>
                        Modificare o cancellare questi tavoli significa perdere le prenotazioni.
                    </IonText><br/>
                    <IonText>
                        Se non si vuole modificare permanentemente la disposizione tavoli della stanza è opportuno
                        procedere creando una "Disposizione temporanea o di giornata".
                    </IonText><br/>
                    <IonText>
                        Altrimenti ricevere un email con la lista delle prenotazioni cancellate. (todo)
                    </IonText>

                    <IonButtons style={{marginTop: 20}}>
                        <IonButton onClick={() => createTemp()} fill={'solid'} size={'large'}>
                            Temporanea
                        </IonButton>
                        <IonButton onClick={() => createPerm()} fill={'solid'} size={'large'}>
                            Permanente
                        </IonButton>
                    </IonButtons>
                </IonContent>
            </IonModal>
        </div>
        }
    </>

});

export default TableEditor;