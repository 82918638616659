import {CLOSE_MODAL, IframeActionTypes, OPEN_MODAL, RESET_BOOKING, SET_BOOKING} from "../types/IframeTypes";


export function setBookingAction(payload: any): IframeActionTypes {
    return {
        type: SET_BOOKING,
        payload: payload
    };
}

export function closeModal(): IframeActionTypes{
    return {
        type: CLOSE_MODAL
    }
}

export function openModal(): IframeActionTypes{
    return {
        type: OPEN_MODAL
    }
}

export function resetBookingAction(): IframeActionTypes {
    return {
        type: RESET_BOOKING
    };
}