import React from 'react';
import {IonButton} from "@ionic/react";


const Form: React.FC<{
    style?: any,
    onFinish?: any
}> = (
    {
        style = {},
        onFinish,
        children
    }
) => {

    return <form style={style}>
        {children}
    </form>
}

export default Form;