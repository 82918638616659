import React, {useEffect, useState} from 'react'
import {

    IonBreadcrumb,
    IonBreadcrumbs,
     IonCard, IonCardContent, IonCardHeader,
    IonContent,
     IonLabel,
    IonLoading,
    IonPage, IonSegment,
    IonSegmentButton, IonText,
} from "@ionic/react";
import {useParams} from "react-router-dom";
import {DataStore} from "@aws-amplify/datastore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass, faLamp, faPencil} from "@fortawesome/pro-solid-svg-icons";
import RoomForm from "./room_components/RoomForm";
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";
import {Location} from "../interfaces/Location";
import {LocationService} from "../services/LocationService";

const CreateRoom: React.FC = () => {


    let {id}: any = useParams();

    const [location, setLocation] = useState<Location>();
    const [retrievingLocation, setRetrievingLocation] = useState<boolean>(true);
    const [activeStep, setActiveStep] = useState<string | undefined>('dettagli');


    useEffect(() => {
        const init = async () => {
            LocationService.getLocation(id).then(setLocation)
                .finally(() => setRetrievingLocation(false))
        }
        init();
    }, [id])


    return <IonPage>
       <Navbar title={'Nuova stanza'}/>
        <IonContent>
            {retrievingLocation ?
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={retrievingLocation}
                    message={'Recupero informazioni...'}
                /> :
                location ? <>
                    <IonBreadcrumbs>
                        <IonBreadcrumb href="/locations">Locations</IonBreadcrumb>
                        <IonBreadcrumb href={`/locations/${id}`}>{location.name}</IonBreadcrumb>
                        <IonBreadcrumb>Nuova stanza</IonBreadcrumb>
                    </IonBreadcrumbs>

                    <IonCard style={{maxWidth: 600, margin: 'auto'}}>
                        <IonCardHeader>
                            <IonText>
                                Ogni locale può avere una o più stanze. Per aggiungere una nuova stanza segui i tre
                                passi
                                che ti
                                permettono di crearla:
                            </IonText>

                            <IonSegment scrollable value={activeStep}
                                        style={{justifyContent: 'center', marginTop: 10}}
                                        onIonChange={(e) => setActiveStep(e.detail.value)}>
                                <IonSegmentButton value="dettagli">
                                    <IonLabel>Dettagli</IonLabel>
                                    <FontAwesomeIcon icon={faPencil} style={{fontSize: 16, marginBottom: 20}}/>
                                </IonSegmentButton>
                                <IonSegmentButton value="superficie">
                                    <IonLabel>Superficie</IonLabel>
                                    <FontAwesomeIcon icon={faCompass} style={{fontSize: 16, marginBottom: 20}}/>
                                </IonSegmentButton>
                                <IonSegmentButton value="tavoli">
                                    <IonLabel>Tavoli</IonLabel>
                                    <FontAwesomeIcon icon={faLamp} style={{fontSize: 16, marginBottom: 20}}/>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCardHeader>

                        <IonCardContent>

                            {activeStep === 'dettagli' &&
                                <RoomForm retrievingLocation={retrievingLocation} location={location}/>}

                        </IonCardContent>
                    </IonCard>
                </> : <>
                    Nessun risultato
                </>
            }


        </IonContent>
        <Footer/>
    </IonPage>
}

export default CreateRoom;