import {createReducer} from '@reduxjs/toolkit';
import {
    AuthenticationState,
    LOGOUT,
    SET_EMAIL,
    SET_SG_USER,
    SET_STATUS,
    SET_USER,
    UPDATE_USER_OBJ
} from "../types/AuthenticationTypes";
import { Preferences } from '@capacitor/preferences';

const initialState: AuthenticationState = {
    user: undefined,
    sgUser: undefined,
    status: undefined,
    userAttributes: {},
    email: ''
};


export const authenticationReducer = createReducer(initialState, (builder => {
    builder
        .addCase(LOGOUT, (state: AuthenticationState) => {
            state.status = undefined;
            state.user = undefined;
            state.sgUser = undefined;
            state.email = '';
            state.userAttributes = {};
            localStorage.clear();
            //sessionStorage.clear();
            Preferences.clear().then(() => {
                setTimeout(() => {
                   window.location.reload();
                }, 200);
            });
        })
        .addCase(SET_USER, (state: AuthenticationState, action: any) => {
            state.user = action.payload.user;
            state.userAttributes = {...action.payload.user.attributes};
            state.status = action.payload.status;
        })
        .addCase(SET_SG_USER, (state: AuthenticationState, action: any) => {
            //sessionStorage.setItem('sgUser', JSON.stringify(action.payload));
            Preferences.set({
                key: 'sgUser',
                value: JSON.stringify(action.payload)
            });

            state.sgUser = action.payload;
        })
        .addCase(UPDATE_USER_OBJ, (state: AuthenticationState, action: any) => {
            state.userAttributes = {...state.userAttributes, [action.payload.key]: action.payload.value}
        })
        .addCase(SET_EMAIL, (state: AuthenticationState, action: any) => {
            state.email = action.payload;
        })
        .addCase(SET_STATUS, (state: AuthenticationState, action: any) => {
            state.status = action.payload;
        });
}));
