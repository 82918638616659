import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import {LangContextS, rootReducer} from "./store";
import {Amplify, AuthModeStrategyType} from 'aws-amplify';
import awsExports from './aws-exports';
import {CurriedGetDefaultMiddleware} from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import LangMapper from "./lang";
import LogRocket from 'logrocket';
import {androidPermissions, requestPermission} from "./permissions";


Amplify.configure(awsExports)

if(process.env.NODE_ENV === 'production'){
    LogRocket.init('stridegate/booking');
}
/*
Amplify.configure({
    ...awsExports,
    DataStore: {
        authMode: AuthModeStrategyType.MULTI_AUTH
    }
});
 */
const store: any = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

ReactDOM.render(
    <Provider store={store}>
        <LangContextS.Provider value={LangMapper}>
            <App/>
        </LangContextS.Provider>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
