import React, {Dispatch} from 'react';
import {
    IonAvatar,
    IonButton,
    IonFab,
    IonFabButton,
    IonImg,
    IonItem,
    IonLabel,
    IonList, useIonActionSheet,
    useIonAlert
} from "@ionic/react";
import {Room} from "../../interfaces/Room";
import S3Image from "../../components/S3Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPlus, faWrench} from "@fortawesome/pro-solid-svg-icons";
import {Location} from '../../interfaces/Location'
import {Storage} from "aws-amplify";
import {RoomService} from "../../services/RoomService";
import {setMessageAction} from "../../store/actions/MessageActions";
import {useDispatch} from "react-redux";

const Info: React.FC<{ location: Location, setLocation: any }> = (
    {
        location,
        setLocation
    }) => {

    const dispatch: Dispatch<any> = useDispatch();
    const [presentAlert] = useIonAlert();
    const [present] = useIonActionSheet();


    const deleteRoom = async (r: Room) => {
        if (r.image) {
            await Storage.vault.remove(r.image);
        }
        RoomService.deleteRoom(r.id)
            .then(() => {
                if (!location) return;
                const clonedLocation: Location = {...location};
                const clonedRooms = [...clonedLocation.rooms];
                clonedLocation.rooms = clonedRooms.filter((room: Room) => room.id !== r.id);
                setLocation(clonedLocation)
                dispatch(setMessageAction('Stanza eliminata', 'success'))
            })
    }

    return <div>
        <div style={{padding: '1em'}}>
            <p>Indirizzo pubblico del locale:</p>
            <a target={'_blank'} href={location.slug}>{location.slug}</a>
        </div>


        {location.rooms?.length === 0 && <div style={{padding: '1em'}}>
            Questo locale non ha ancora stanze.
            <IonImg src={'assets/test-1.jpg'}
                    style={{borderRadius: 15, marginBottom: 32, marginTop: 32}}/>
            Clicca sul pulsante + in basso a destra per creare la tua prima stanza.
        </div>}


        <IonList>
            {
                location.rooms?.map((r: Room, index: number) => {
                    return <IonItem key={'location-' + index}>
                        <IonAvatar slot="start">
                            <S3Image name={r.name + ' image'} fileName={r.image}/>
                        </IonAvatar>
                        <IonLabel>{r.name}</IonLabel>
                        <IonButton

                            color={'secondary'}
                            href={'/locations/' + location.id + '/rooms/' + r.id}
                            style={{marginRight: 15}}
                        >
                            <FontAwesomeIcon icon={faEye}/>
                        </IonButton>
                        <IonButton
                            onClick={() =>
                                present([
                                    {
                                        text: 'Elimina',
                                        role: 'destructive',
                                        data: {
                                            type: 'delete'
                                        },
                                        handler: () => {
                                            presentAlert({
                                                header: 'Sei veramente sicuro di voler elminare questa stanza? (' + r.name + ')',
                                                buttons: [
                                                    {
                                                        text: 'No, annulla',
                                                        role: 'cancel'
                                                    },
                                                    {
                                                        text: 'Si, elimina',
                                                        role: 'confirm',
                                                        handler: () => {
                                                            deleteRoom(r)
                                                        }
                                                    }
                                                ],
                                            })
                                        }
                                    },
                                    {
                                        text: 'Annulla'
                                    }
                                ], 'Locale: ' + r.name)
                            }
                            color={'tertiary'}>
                            <FontAwesomeIcon
                                icon={faWrench}
                            />
                        </IonButton>
                    </IonItem>
                })
            }
        </IonList>


        <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton href={'/locations/' + location.id + '/create_room'}>
                <FontAwesomeIcon icon={faPlus}/>
            </IonFabButton>
        </IonFab>
    </div>

}
export default Info;