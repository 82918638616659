import {Auth} from "aws-amplify";
import {setEmailAction, setStatusAction} from "../../store/actions/AuthenticationActions";
import {setMessageAction} from "../../store/actions/MessageActions";

class ResetPw {
    setSubmitInProgress: any;
    dispatch: any;

    setInvalidCredentials: any;
    setSendingMail: any;

    constructor(setSubmitInProgress: any, dispatch: any, setInvalidCredentials: any, setSendingMail: any) {
        this.setSubmitInProgress = setSubmitInProgress;
        this.dispatch = dispatch;
        this.setInvalidCredentials = setInvalidCredentials;
        this.setSendingMail = setSendingMail;
    }

    completeNewPassword = (email: string, newPassword: string, verificationCode: string) => {
        this.setSubmitInProgress(true);
        Auth.forgotPasswordSubmit(
            email,
            verificationCode,
            newPassword
        )
            .then((res) => {
                this.dispatch(setMessageAction('La tua password è stata resettata correttamente', 'success'))
                // TODO LANG
                this.dispatch(setEmailAction(email));
                this.dispatch(setStatusAction('SIGN_IN'));
            })
            .catch(err => {
                this.setInvalidCredentials(true);
                this.dispatch(setMessageAction(err.message, 'danger'))
            })
            .finally(() => {
                this.setSubmitInProgress(false);
            });
    }

    forgotPassword = (email: string) => {
        if (!email) {
            this.dispatch(setMessageAction('Inserisci un email valida', 'danger'))
            // TODO LANG
            return;
        }
        this.setSendingMail(true);
        Auth.forgotPassword(email)
            .then(() =>  this.dispatch(setStatusAction('RESET_PW')))
            .catch(err => {

            })
            .finally(() => {
                this.dispatch(setMessageAction('Ti abbiamo inviato un email contenente le istruzioni necessarie', 'success'))
                // TODO LANG
                this.setSendingMail(false);
            });
    }

}

export default ResetPw;
