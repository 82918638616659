import {
    IonApp,
    setupIonicReact
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Authorizer from "./auth/Authorizer";
import SignUp from "./auth/slots/SignUp";
import Message from "./components/Message";
import Dashboard from "./pages/Dashboard";
import Locations from "./pages/Locations";
import LocationPage from "./pages/LocationPage";
import CreateRoom from "./pages/CreateRoom";
import RoomPage from "./pages/RoomPage";
import IframeLocation from "./pages/IframeLocation";
import IframeRoom from "./pages/IframeRoom";
import BookPage from "./pages/BookPage";
import MyBookings from "./pages/MyBookings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Map from "./pages/Map";
import Settings from "./pages/Settings";
import Plans from "./pages/Plans";
import RoomPageExternal from "./pages/RoomPageExternal";
import RoomPageExternalTable from "./pages/RoomPageExternalTable";

setupIonicReact();

const App: React.FC = () => (
    <IonApp>
        <IonReactRouter>
            <Authorizer
                mapper={{}}
                paths={[
                    {
                        path: '/',
                        redirect: '/dashboard',
                    },
                    {
                        path: '/privacyPolicy',
                        element: <PrivacyPolicy/>,
                        protected: false,
                        middlewares: []
                    },
                    {
                        path: '/termsAndConditions',
                        element: <TermsAndConditions/>,
                        protected: false,
                        middlewares: []
                    },


                    {
                        path: '/external/locations/:id/rooms/:roomId',
                        element: <RoomPageExternal/>,
                        protected: false,
                        middlewares: []
                    },
                    {
                        path: '/external/locations/:id/rooms/:roomId/table',
                        element: <RoomPageExternalTable/>,
                        protected: false,
                        middlewares: []
                    },
                    {
                        path: '/iframe/room/:id/:roomId',
                        element: <IframeRoom/>,
                        protected: false,
                        middlewares: []
                    },


                    // TODO deprecated
                    {
                        path: '/locations',
                        element: <Locations/>,
                        protected: true,
                        middlewares: []
                    },
                    {
                        path: '/locations/:id',
                        element: <LocationPage/>,
                        protected: true,
                        middlewares: []
                    },
                    {
                        path: '/locations/:id/create_room',
                        element: <CreateRoom/>,
                        protected: true,
                        middlewares: []
                    },
                                        {
                        path: '/iframe/:id/:slug',
                        element: <IframeLocation/>,
                        protected: false,
                        middlewares: []
                    },
                    {
                        path: '/locations/:id/rooms/:roomId',
                        element: <RoomPage/>,
                        protected: true,
                        middlewares: []
                    },

                ]}/>
        </IonReactRouter>
        <Message/>
    </IonApp>
);

export default App;
