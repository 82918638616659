import React from 'react'
import {
    IonFooter, IonItem, IonList, IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {Link} from "react-router-dom";

const Footer: React.FC = () => {

    return <IonFooter className={'main-footer'} >
        <IonToolbar style={{fontSize: '80%'}}>
            <div style={{textAlign: 'center', marginBottom: 6, marginTop: 6}}>
                <div style={{marginTop: 8}}>support@stridegate.com</div>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <Link to={'/privacyPolicy'} style={{color: '#161616', textDecoration: 'none'}}>
                    Privacy Policy
                </Link>

                <Link to={'/termsAndConditions'}  style={{color: '#161616', textDecoration: 'none'}}>
                    Termini e condizioni
                </Link>
            </div>
        </IonToolbar>
    </IonFooter>
}

export default Footer;