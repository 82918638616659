import {createReducer} from '@reduxjs/toolkit';
import {LangState, SET_LANG} from "../types/LangTypes";


const initialState: LangState = {
    lang: 'it'
};


export const langReducer = createReducer(initialState, (builder => {
    builder
        .addCase(SET_LANG, (state: LangState, action: any) => {
            state.lang = action.payload;
        });
}));
