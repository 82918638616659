import {Camera, CameraResultType, CameraSource, Photo} from '@capacitor/camera';

export async function takePicture(): Promise<Photo> {

   return  await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
        promptLabelPhoto: 'Gallery',
        promptLabelPicture : 'Camera',
        promptLabelHeader : 'Cancel',
    });

}