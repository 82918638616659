import {CognitoUserInterface} from "../../interfaces/CognitoUserInterface";

export const SET_USER = 'SET_USER';
export const SET_SG_USER = 'SET_SG_USER';
export const SET_STATUS = 'SET_STATUS';
export const LOGOUT = 'LOGOUT';
export const SET_EMAIL = 'SET_EMAIL';
export const UPDATE_USER_OBJ = 'UPDATE_USER_OBJ';

export interface AuthenticationState {
    user: CognitoUserInterface | undefined;
    sgUser: any,
    userAttributes: any;
    status: Status | undefined;
    email: string;
}

interface setUser {
    type: typeof SET_USER,
    payload: { user: CognitoUserInterface, status: Status }
}

interface setSgUser {
    type: typeof SET_SG_USER,
    payload: any
}

interface updateUserObj {
    type: typeof UPDATE_USER_OBJ,
    payload: { key: string, value: any }
}

interface setStatus {
    type: typeof SET_STATUS,
    payload: Status
}

interface setEmail {
    type: typeof SET_EMAIL,
    payload: string
}

interface logout {
    type: typeof LOGOUT
}


export type Status =
    'MFA' |
    'RESET_PW' |
    'SETUP_MFA' |
    'SET_SG_USER' |
    'SIGNED_IN' |
    'SIGN_IN' |
    'SIGN_UP' |
    'CONFIRM_USER_REGISTRATION' |
    'ASK_RESET_PW' |
    'FORCE_CHANGE_PW';

export type AuthenticationActionTypes = setUser | updateUserObj | setStatus | setEmail | logout | setSgUser;
