import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {
    DatetimeCustomEvent,
    IonButton, IonButtons,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonContent, IonDatetime, IonFab, IonFabButton, IonItem, IonList,
    IonModal, IonText, IonTextarea
} from "@ionic/react";
import {fabric} from "fabric";
import {Card} from "@aws-amplify/ui-react";
import Stepper from "../components/Stepper";
import {faCalendarDays, faEnvelope, faTablePicnic, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {format, parseISO} from 'date-fns';
import {setMessageAction} from "../store/actions/MessageActions";
import {useDispatch} from "react-redux";
import {closeModal, resetBookingAction, setBookingAction} from "../store/actions/IframeActions";
import it from 'date-fns/locale/it';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertUTCDayToString} from "../functions/convertUTCDayToString";
import {Room} from "../interfaces/Room";
import {Table} from "../interfaces/Table";
import {Calendar} from "../interfaces/Calendar";
import {Location} from "../interfaces/Location";
import {splitCalendarHours} from "../functions/splitCalendarHours";
import {RoomService} from "../services/RoomService";
import {now} from "../functions/now";
import {BookingService} from "../services/BookingService";
import {doesBookingHasTable, findBookingByTableId} from "../functions/bookingTable";
import {Booking} from "../interfaces/Booking";


const BookingWizard: React.FC<{
    id: number,
    roomId: number,
    calendars: Calendar[],
    room: Room,
    location: Location,
    owner?: boolean
}> = (
    {
        id,
        roomId,
        calendars,
        room,
        location,
        owner = false
    }) => {

    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();

    const [bookings, setBookings] = useState<any[]>([]);
    const canvasEl = useRef<HTMLCanvasElement>(null)
    const wrapper = useRef<HTMLDivElement>(null);
    const [selectedTable, setSelectedTable] = useState<Table>()
    const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [breakpoint, setBreakpoint] = useState<number>(0.5)
    const [date, setDate] = useState<any>(now());
    const [ionDate, setIonDate] = useState<any>(null);

    const [currentStep, setCurrentStep] = useState<number>(0);


    const [selectedTables, setSelectedTables] = useState<Table[]>([]);

    const [doOnce, setDoOnce] = useState<boolean>(false)
    const [text, setText] = useState<string>();

    useEffect(() => {
        return () => {
            dispatch(closeModal())
        }
    }, [])

    useEffect(() => {
        const handleElement = (obj: any) => {
            if (obj.selected) {
                if (!showModal) {
                    setShowModal(true)
                }
                console.log('object selected table', obj.selected[0].table)
                setSelectedTable(obj.selected[0].table);
            } else {
                if (showModal) {
                    setShowModal(false)
                }
                setSelectedTable(undefined)
            }
        }

        if (fabricCanvas) {
            if (!showModal) {
                fabricCanvas.discardActiveObject().renderAll();
            }
            console.log('add event listener for fabric canvas');
            fabricCanvas.on('selection:cleared', handleElement);
            fabricCanvas.on('selection:updated', handleElement);
            fabricCanvas.on('selection:created', handleElement);
        }
        return () => {
            if (fabricCanvas) {
                console.log('remove event listener for fabric canvas');
                fabricCanvas.off('selection:cleared', handleElement);
                fabricCanvas.off('selection:updated', handleElement);
                fabricCanvas.off('selection:created', handleElement);
            }
        }
    }, [fabricCanvas, showModal])


    useEffect(() => {
        let canvas: any = null;

        if (currentStep === 1) {
            RoomService.checkAvailability(date, id, roomId).then(setBookings)
        }

        if (currentStep === 1 && !doOnce) {
            canvas = new fabric.Canvas(canvasEl.current);
            canvas.selection = false; // disable group selection

            if (room && room.structure) {
                canvas.loadFromJSON(room.structure, canvas.renderAll.bind(canvas), function (o: any, object: any) {
                    object.set('selectable', false);
                });
            }

            room?.tables?.forEach((t: Table) => {
                if (t.coordinates) {
                    fabric.util.enlivenObjects([JSON.parse(t.coordinates)], function (objects: any) {
                        objects.forEach(function (o: any) {
                            o.table = t;
                            //o.selectable = false;
                            o.lockMovementX = true;
                            o.lockMovementY = true;
                            o.lockRotation = true;
                            o.lockScalingFlip = true;
                            o.lockScalingX = true;
                            o.lockScalingY = true;
                            o.hasControls = false;
                            o._objects[0].set("strokeWidth", 3);
                            o._objects[0].set("stroke", 'white');

                            // se occupato red!
                            o._objects[0].set("fill", 'gray');
                            o._objects[1].set("fill", 'white');
                            canvas.add(o);
                        });
                    }, '');
                }
            })
            setFabricCanvas(canvas);
        }
    }, [currentStep])


    useEffect(() => {
        if (bookings && fabricCanvas) {
            fabricCanvas.getObjects().forEach(function (o: any) {
                if (!o.table) return;
                if (doesBookingHasTable(bookings, o.table.id)) {
                    o._objects[0].set("fill", 'yellow');
                    o._objects[1].set("fill", 'black');
                }
            });
            fabricCanvas.renderAll();
        }
    }, [bookings, fabricCanvas])


    useEffect(() => {
        if (fabricCanvas) {
            fabricCanvas.getObjects().forEach(function (o: any) {
                if (!o.table) return;
                if (selectedTables.some((i: Table) => i.id === o.table.id)) {
                    o._objects[0].set("fill", 'green');
                    o._objects[1].set("fill", 'white');
                } else {

                    if (bookings && bookings[o.table.id] !== undefined) {
                        o._objects[0].set("fill", 'yellow');
                        o._objects[1].set("fill", 'black');
                    } else {
                        o._objects[0].set("fill", 'gray');
                        o._objects[1].set("fill", 'white');
                    }
                }
            });
            fabricCanvas.renderAll();
        }
    }, [selectedTables, fabricCanvas])


    const goStep1 = () => {
        const today = new Date();
        // today.setHours(today.getHours())
        today.setMinutes(today.getMinutes() - 15)
        const d = parseISO(date);
        if (d < today) {
            dispatch(setMessageAction('Non puoi prenotare nel passato! Scegli una data valida', 'warning'))
            return;
        }
        const calendarDays = calendars.filter((c: Calendar) =>
            c.day_of_the_week === convertUTCDayToString(new Date(ionDate).getUTCDay())
        )!;
        let isOk = false;


        if (calendarDays.length === 0) {
            setCurrentStep(1)
            return;
        }


        let time = parseInt(`${d.getHours()}${d.getMinutes()}${d.getMinutes() === 0 ? 0 : null}`);

        calendarDays.map((c: Calendar, index: number) => {
            if (time >= parseInt(c.from.replace(':', '')) && time <= parseInt(c.to.replace(':', ''))) {
                isOk = true;
            }
        })

        if (isOk) {
            setCurrentStep(1)
        } else {
            dispatch(setMessageAction('Seleziona un orario tra quelli disponibili', 'warning'))
        }
    }

    const goStep2 = () => {
        if (selectedTables.length <= 0) {
            dispatch(setMessageAction('Aggiungi almeno un tavolo!', 'warning'))
            return;
        }
        setCurrentStep(2)
    }

    const complete = () => {
        if (!location) return;
        if (selectedTables.length <= 0) {
            dispatch(setMessageAction('Aggiungi almeno un tavolo!', 'warning'))
            return;
        }

        if (owner) {
            BookingService.create({
                date: date,
                notes: text,
                room_id: roomId,
                location_id: location.id,
                tables: JSON.stringify(selectedTables),
                is_location: owner
            }).then((r) => {
                dispatch(setMessageAction('Prenotazione effettuata', 'success'))
                dispatch(closeModal())
            })
                .catch((e) => {
                    console.log('error while booking:', e);
                    dispatch(setMessageAction('Errore durante la prenotazione', 'danger'))
                })
        } else {
            dispatch(setBookingAction({
                tables: selectedTables,
                date: date,
                notes: text,
                roomID: roomId,
                locationID: location.id
            }))
            history.push('/iframe/book')
        }
    }

    const addTable = () => {
        {
            if (!selectedTable || !location || !selectedTable.id) return;

            let go = true;


            if (doesBookingHasTable(bookings, selectedTable.id)) {
                findBookingByTableId(bookings, selectedTable.id).map((t: any) => {
                    const cDate = new Date(date).getTime() / 1000;
                    const startDate = new Date(t).getTime() / 1000;
                    const endDate = new Date(t).setHours(new Date(t).getHours() + (location.average_length_of_meal ?? 1)) / 1000;

                    console.log('date is', format(new Date(cDate * 1000), 'HH:mm'));
                    console.log('startDate is', format(new Date(startDate * 1000), 'HH:mm'));
                    console.log('endDate is', format(new Date(endDate * 1000), 'HH:mm'));

                    if (cDate >= startDate && cDate <= endDate) {
                        dispatch(setMessageAction('Non puoi occupare un tavolo già prenotato', 'warning'))
                        go = false;
                        return;
                    }
                });
            }

            if (!go) return;

            if (!selectedTables.some((t: Table) => t.id === selectedTable.id)) {
                setSelectedTables([...selectedTables, selectedTable])
            }
            setShowModal(false);
        }
    }

    const isDateEnabled = (dateString: string) => {
        const date = new Date(dateString);
        const utcDay = date.getUTCDay();
        const days: number[] = [];
        calendars.map((c: Calendar | null) => {
            if (c) {
                switch (c.day_of_the_week) {
                    case 'mon':
                        days.push(1)
                        break;
                    case 'tue':
                        days.push(2)
                        break;
                    case 'wed':
                        days.push(3)
                        break;
                    case 'thu':
                        days.push(4)
                        break;
                    case 'fri':
                        days.push(5)
                        break;
                    case 'sat':
                        days.push(6)
                        break;
                    case 'sun':
                        days.push(0)
                        break;
                }
            }
        })
        return days.includes(utcDay);
    }


    return <div>

        {!location || !room ? 'loading' : <>

            {!owner &&
                <IonFab vertical="top" horizontal="end" slot="fixed">
                    <IonFabButton size={'small'} color={'light'} href={'/'}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </IonFabButton>
                </IonFab>
            }

            <Card>
                <IonCardHeader>
                    <IonCardSubtitle>Nuova prenotazione</IonCardSubtitle>
                    <IonCardTitle>{location?.name}</IonCardTitle>
                </IonCardHeader>

                <Stepper
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    steps={[
                        {
                            name: 'Seleziona data e ora',
                            icon: faCalendarDays,
                            element: <>
                                <IonDatetime hourCycle={'h23'}
                                             showDefaultTimeLabel={false}
                                             minuteValues={'0,15,30,45'}
                                             value={ionDate}
                                             isDateEnabled={isDateEnabled}
                                             defaultValue={now(1)}
                                             onIonChange={(e: DatetimeCustomEvent) => {
                                                 if (e.detail && e.detail.value) {
                                                     setDate(e.detail.value)
                                                     setIonDate(e.detail.value)
                                                 }
                                             }}
                                             locale='it-IT' size={'cover'}>
                                    <span slot={'time-label'}>Seleziona l'orario</span>
                                </IonDatetime>

                                <div style={{textAlign: 'center', fontSize: '120%', marginTop: 8}}>
                                    Prenota
                                    per {date ? format(parseISO(date), 'dd MMMM HH:mm', {locale: it}) : ''}
                                </div>
                                {ionDate && <div style={{textAlign: 'center', fontSize: '110%', marginTop: 8}}>
                                    Il locale è aperto {splitCalendarHours(calendars, ionDate)}
                                </div>}

                                <div style={{textAlign: 'center', padding: 16}}>
                                    <IonButton color={'primary'}
                                               onClick={() => {
                                                   goStep1();
                                               }}
                                               fill={'solid'}>Avanti</IonButton>
                                </div>
                            </>
                        },
                        {
                            name: 'Seleziona uno o più tavoli oppure inserisci il numero di persone per cui vuoi prenotare.',
                            icon: faTablePicnic,
                            element: <div>

                                <IonText style={{textAlign: 'center', display: 'block'}}>Data:
                                    {' ' + format(parseISO(date), 'dd MMMM HH:mm', {locale: it})}
                                </IonText>

                                <div
                                    ref={wrapper}
                                    style={{
                                        width: '100%',
                                        height: 300,
                                        overflow: 'scroll',
                                        marginTop: 8
                                    }}>
                                    <div

                                        className={'container'}
                                        style={{position: "relative"}}>
                                        <canvas
                                            style={{
                                                position: "relative",
                                                border: "lightgrey 1px solid",
                                            }}
                                            width="1200" height="1200"
                                            ref={canvasEl}/>

                                    </div>
                                </div>

                                <div style={{textAlign: 'center', marginTop: 16}}>Tavoli prenotati:</div>
                                <IonList>
                                    {selectedTables.map((i: Table) => <IonItem key={'t-' + i.id}>
                                        Tavolo da {i.seats} posti
                                    </IonItem>)}
                                </IonList>

                                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 16}}>
                                    <IonButton color={'light'}
                                               style={{color: 'white'}}
                                               onClick={() => {
                                                   setCurrentStep(0)
                                               }}
                                               fill={'solid'}>Indietro</IonButton>
                                    <IonButton color={'primary'}
                                               onClick={() => {
                                                   goStep2()
                                               }}
                                               fill={'solid'}>Avanti</IonButton>
                                </div>
                            </div>
                        },
                        {
                            name: 'Riepilogo prenotazione',
                            icon: faEnvelope,
                            element: <>
                                <div style={{textAlign: 'center', fontSize: '120%', marginTop: 8}}>
                                    {date ? format(parseISO(date), 'dd MMMM HH:mm', {locale: it}) : ''}
                                </div>
                                <div style={{textAlign: 'center', marginTop: 16}}>Tavoli prenotati:</div>
                                <IonList>
                                    {selectedTables.map((i: Table) => <IonItem key={'t-' + i.id}>
                                        Tavolo da {i.seats} posti
                                    </IonItem>)}
                                </IonList>

                                <IonTextarea
                                    autoGrow={true}
                                    cols={4}
                                    placeholder="Inserisci qui eventuali note..."
                                    value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>

                                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 16}}>
                                    <IonButton color={'light'}
                                               style={{color: 'white'}}
                                               onClick={() => {
                                                   setCurrentStep(1)
                                               }}
                                               fill={'solid'}>Indietro</IonButton>
                                    <IonButton color={'primary'}
                                               onClick={() => {
                                                   complete()
                                               }}
                                               fill={'solid'}>Completa</IonButton>
                                </div>
                            </>
                        }
                    ]}/>


                <IonCardContent>

                </IonCardContent>
            </Card>

            {(selectedTable && location && selectedTable.id) &&
                <IonModal isOpen={showModal}
                          showBackdrop={true}
                          onDidDismiss={() => setShowModal(false)}
                          backdropDismiss={true}
                          initialBreakpoint={breakpoint}
                          onIonBreakpointDidChange={(e) => setBreakpoint(e.detail.breakpoint)}
                          breakpoints={[0.25, 0.5, 0.75]}>
                    <IonContent className="ion-padding">
                        <IonText style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginTop: 16,
                            fontSize: '110%',
                            marginBottom: 8
                        }}>Tavolo
                            da {selectedTable.seats ? (selectedTable.seats > 1 ? selectedTable.seats + ' posti' : 'singolo') : '[posti non definiti]'}</IonText>
                        {!(selectedTables.some((t: Table) => t.id === selectedTable.id)) ?
                            <IonText>Vuoi prenotare questo tavolo?</IonText> :
                            <IonText>Vuoi rimuovere questo tavolo dalla prenotazione?</IonText>
                        }
                        <br/>
                        {doesBookingHasTable(bookings, selectedTable.id) && <>
                            <IonText>Questo tavolo è già stato prenotato nelle seguenti fascie orarie:</IonText>
                            <IonList>
                                {findBookingByTableId(bookings, selectedTable.id).map((t: Booking, index: number) =>
                                    <IonItem key={'it-' + index}>
                                        {format(new Date(t.booked_at), 'HH:mm')} -
                                        {' ' + format(new Date(t.booked_at).setHours(new Date(t.booked_at).getHours() + (location?.average_length_of_meal ?? 1)), 'HH:mm')}
                                    </IonItem>)}
                            </IonList>
                        </>
                        }

                        <IonButtons style={{justifyContent: 'space-between', marginTop: 16}}>
                            <IonButton color={'light'}
                                       onClick={() => setShowModal(false)}
                                       style={{width: '40%'}}
                                       size={'large'}
                                       fill={'solid'}>No</IonButton>
                            {(selectedTables.some((t: Table) => t.id === selectedTable.id)) ?
                                <IonButton color={'danger'}
                                           style={{width: '40%'}}
                                           onClick={() => {
                                               setSelectedTables([...selectedTables.filter((i: Table) => i.id !== selectedTable.id)])
                                               setShowModal(false);
                                           }}
                                           size={'large'}
                                           fill={'solid'}>Rimuovi</IonButton>
                                :
                                <IonButton color={'primary'}
                                           style={{width: '40%'}}
                                           onClick={() => addTable()}
                                           size={'large'}
                                           fill={'solid'}>Si</IonButton>
                            }
                        </IonButtons>

                    </IonContent>
                </IonModal>
            }
        </>
        }
    </div>
}

export default BookingWizard;