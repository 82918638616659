import axios, {AxiosInstance, AxiosRequestConfig, AxiosError} from "axios";
import {Auth} from "aws-amplify";
import AuthService from "../services/AuthService";
import axiosRetry from 'axios-retry';
//import { Preferences } from '@capacitor/preferences';

let jwt = '';
const interceptor = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 60000,
});
const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;
let refreshing_token = false;


axiosRetry(interceptor, {
    retries: 3,
    retryDelay: (retryCount: number) => {
        console.log('retry attempt', retryCount)
        return retryCount*200},
    retryCondition: (error: AxiosError) => {
        // if retry condition is not specified, by default idempotent requests are retried
        console.log('error status', error.response, error.response?.status)
        return error.response?.status === 425;
    },
});


export const AxiosInterceptor = async (): Promise<AxiosInstance> => {
    await Auth.currentSession().then(async (session) => {
        const expiration = session.getIdToken().getExpiration();
        const currentTimeSeconds = Math.round(+new Date() / 1000);
        jwt = session.getIdToken().getJwtToken();

        if (expiration - currentTimeSeconds <= 0 && !refreshing_token) {
            console.log('need to refresh the token')
            refreshing_token = true;
            Auth.currentAuthenticatedUser().then(user => {
                user.getCachedDeviceKeyAndPassword();
                user.getDevice({
                    onSuccess: (deviceKey: any) => {
                        const device_key = deviceKey.Device.DeviceKey;
                        console.log('device_key', device_key);
                        AuthService.refreshToken(session.getRefreshToken(), device_key).then(async (r) => {
                            const authRes = r.AuthenticationResult;
                            const key1 = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWSUPWC_ID}.${user.username}.idToken`;
                            const key2 = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWSUPWC_ID}.${user.username}.accessToken`;
                            /*
                            await Preferences.set({
                                key: key1,
                                value: authRes.IdToken
                            });
                            await Preferences.set({
                                key: key2,
                                value: authRes.AccessToken
                            });*/
                            localStorage.setItem(key1, authRes.IdToken);
                            localStorage.setItem(key2, authRes.AccessToken);
                            refreshing_token = false;
                        });

                    }, onFailure: (e: any) => console.log('err', e)
                });
            });
        }

    });
    return new Promise((resolve, reject) => {
        (function waitForFoo() {
            if (!refreshing_token) return resolve(interceptor);
            setTimeout(waitForFoo, 30);
        })();
    });

};


interceptor.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if(!config.headers){
            console.log('Error while setting interceptor')
            return;
        }
        config.headers.Authorization = `Bearer ${jwt}`;

        return new Promise((resolve, reject) => {
            let interval = setInterval(() => {
                if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                    PENDING_REQUESTS++;
                    clearInterval(interval);
                    resolve(config);
                }
            }, INTERVAL_MS);
        });
    },
    (error) => {
        return Promise.reject(error);
    }
);


interceptor.interceptors.response.use(response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
}, async (error) => {
    if (error.response && error.response.status === 401) {
        console.log('alert! sign out')
        //await Auth.signOut();
    }
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
});
