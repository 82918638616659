import loginIt from "./it/Login";
import loginEn from "./en/Login";

const LangMapper: any = {

    it: {
        login: loginIt
    },
    en: {
        login: loginEn
    }

}
export default LangMapper;
