import {AxiosInterceptor} from "./AxiosInterceptor";
import axios, {AxiosInstance, AxiosResponse} from "axios";
import {Location} from "../interfaces/Location";
import {LatLngBounds} from "@capacitor/google-maps/dist/typings/definitions";

export class LocationService {

    static listMyLocations = async (): Promise<Location[]> => {
        const url = `${process.env.REACT_APP_URL}/myLocations`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.get(url).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static createNewLocation = async (data: any): Promise<Location> => {
        const url = `${process.env.REACT_APP_URL}/locations`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, data).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static updateLocation = async (id: number, data: any): Promise<Location> => {
        const url = `${process.env.REACT_APP_URL}/locations/${id}`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.put(url, data).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

    static deleteLocation = async (id: number): Promise<null> => {
        const url = `${process.env.REACT_APP_URL}/locations/${id}`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.delete(url);
            });
    };

    static getLocation = async (id: number): Promise<Location> => {
        const url = `${process.env.REACT_APP_URL}/locations/${id}`;
        return axios.get(url).then((res: AxiosResponse) => {
            return res.data;
        });
    };

    static getLocationBySlug = async (id: number, slug: string): Promise<Location> => {
        const url = `${process.env.REACT_APP_URL}/location_by_slug/${id}/${slug}`;
        return await axios.get(url).then((res: AxiosResponse) => {
            return res.data;
        });
    };


    static searchByArea = async (r: LatLngBounds): Promise<Location[]> => {
        const url = `${process.env.REACT_APP_URL}/search_location_by_area`;
        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, r).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

}