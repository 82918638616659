import {AndroidPermissions} from '@ionic-native/android-permissions';
import {Capacitor} from "@capacitor/core";

export const androidPermissions = [
    AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
    AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
    AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
    AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
    AndroidPermissions.PERMISSION.CAMERA,
]

export const requestPermission = (permission: string) => {
    if (Capacitor.isNativePlatform()) {
        AndroidPermissions.checkPermission(permission).then(
            result => {
                if (!result.hasPermission) {
                    AndroidPermissions.requestPermission(permission);
                }
            }
        )
    }
}