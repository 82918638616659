import {AxiosInstance, AxiosResponse} from "axios";
import {AxiosInterceptor} from "./AxiosInterceptor";

export class GmapService {


    static geocode = async (address: string): Promise<any> => {
        const url = `${process.env.REACT_APP_URL}/geocode`;

        return await AxiosInterceptor().then(
            (ax: AxiosInstance) => {
                return ax.post(url, {
                    address: address
                }).then((res: AxiosResponse) => {
                    return res.data;
                });
            });
    };

}