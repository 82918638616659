import React, {Dispatch, forwardRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {Auth} from "aws-amplify";
import {setSgUserAction, setStatusAction, setUserAction} from "../store/actions/AuthenticationActions";
import {IAdvancedRouteProps} from "../interfaces/IAdvancedRouteProps";
import AuthService from "../services/AuthService";
import {Redirect, Route, useHistory, useParams} from 'react-router-dom';
import SignIn from "./slots/SignIn";
import SignUp from "./slots/SignUp";
import Mfa from "./slots/Mfa";
import ChangePassword from "./slots/ChangePassword";
import {MiddlewareInterface} from "../interfaces/IMiddleware";
import {IonRouterOutlet} from "@ionic/react";
import {setMessageAction} from "../store/actions/MessageActions";
import Menu from "../shared/Menu";
import NotFound from "../pages/NotFound";
import animationBuilder from "../shared/AnimationBuilder";
import {Preferences} from '@capacitor/preferences';

interface IAdvancedAuthorizerRouteProps extends IAdvancedRouteProps {
    protected?: boolean;
    element?: any;
    redirect?: string;
}


const Authorizer = forwardRef((props: {
    paths: IAdvancedAuthorizerRouteProps[],
    mapper: {
        [index: string]: MiddlewareInterface
    },
    lang?: any,
    requiresLogin?: boolean
}, ref) => {


    const {id} = useParams<{ id: string }>();
    const {status} = useSelector<RootState, any>((state: RootState) => state.authReducer);
    const history = useHistory();

    const dispatch: Dispatch<any> = useDispatch();
    const [loginFlow, setLoginFlow] = useState<boolean>(false);

    useEffect(() => {
        if (loginFlow && status === 'SIGNED_IN') {
            loginAuth();
        }
    }, [status]);

    useEffect(() => {
        // First time login
        loginAuth();
    }, []);

    const loginAuth = () => {
        Auth.currentAuthenticatedUser().then(async (u) => {
            dispatch(setUserAction({user: u, status: 'SIGNED_IN'}))
            //const sgUser = sessionStorage.getItem('sgUser');
            const sgUser = await Preferences.get({key: 'sgUser'});
            if (sgUser !== null && sgUser.value) {
                dispatch(setSgUserAction(JSON.parse(sgUser.value)));
            } else {
                AuthService.getUser()
                    .then((res: any) => {
                        dispatch(setSgUserAction(res));
                    })
                    .catch((err: any) => {
                        dispatch(setMessageAction('Errore durante la login, contatta il nostro supporto', 'danger'))
                        localStorage.clear();
                        dispatch(setStatusAction('SIGN_IN'));
                        // TODO LANG
                    }).finally(() => {
                    setLoginFlow(true);
                });
            }
        }).catch((error: any) => {
            console.log('get user fail: error', error);
            dispatch(setStatusAction('SIGN_IN'));
            setLoginFlow(true);
        });
    }


    const renderElement = (el: IAdvancedAuthorizerRouteProps, index: number) => {
        let renderComponent: any = el.element;

        if (el.protected) {
            if (!status) {
                return 'loading spin xd';
            } else {
                switch (status) {
                    case "SIGN_IN":
                    case 'CONFIRM_USER_REGISTRATION':
                    case 'FORCE_CHANGE_PW':
                        return <SignIn/>;
                    case "SIGN_UP":
                        return <SignUp/>;
                    case "MFA":
                        return <Mfa/>;
                    case "RESET_PW":
                    case "ASK_RESET_PW":
                        return <ChangePassword/>;
                    case "SETUP_MFA":
                        break
                    case "SIGNED_IN":
                        const len = el.middlewares ? el.middlewares.length : 0;
                        if (len > 0) {
                            for (let k = 0; k < len; k++) {
                                const name = el.middlewares![k];
                                const components = name.split(':');

                                const middleware = props.mapper[components[0]];
                                const handle = middleware.handle(components, id ? parseInt(id) : -1);
                                if (!handle) {
                                    if (middleware.redirect) {
                                        history.push('/');
                                        return <></>;
                                    }
                                    return middleware.element;
                                }
                            }
                        }
                        break;
                }

            }

        }

        return renderComponent;
    }

    const recursiveExplorer = (el: IAdvancedAuthorizerRouteProps, index: number) => {
        if (el.redirect) {
            return <Redirect key={`redirect-${index}`}
                             from={typeof el.path === 'string' ? el.path : ''} to={el.redirect} exact/>
        }
        return <Route path={el.path}
                      key={`route-${index}`}>
            {renderElement(el, index)}
        </Route>
    }


    if (status === undefined) return null;
    return (
        <>
            {!window.location.pathname.includes('/external/locations/') &&
                <Menu/>
            }
            <IonRouterOutlet id={"app"} animation={animationBuilder}>
                {props.paths?.map((el: IAdvancedAuthorizerRouteProps, index: number) => {
                    return recursiveExplorer(el, index);
                })}

                <Route component={NotFound}/>
            </IonRouterOutlet>
        </>
    );
});

export default Authorizer;
