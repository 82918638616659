import {
    AuthenticationActionTypes,
    LOGOUT,
    SET_EMAIL, SET_SG_USER,
    SET_STATUS,
    SET_USER,
    Status, UPDATE_USER_OBJ
} from "../types/AuthenticationTypes";
import {CognitoUserInterface} from "../../interfaces/CognitoUserInterface";

export function setUserAction(obj: { user: CognitoUserInterface, status: Status}): AuthenticationActionTypes {
    return {
        type: SET_USER,
        payload: obj
    };
}

export function setSgUserAction(sgUser: any): AuthenticationActionTypes {
    return {
        type: SET_SG_USER,
        payload: sgUser
    };
}


export function updateUserObjAction(obj: {key: string, value: any}): AuthenticationActionTypes {
    return {
        type: UPDATE_USER_OBJ,
        payload: obj
    };
}

export function setStatusAction(status: Status): AuthenticationActionTypes {
    return {
        type: SET_STATUS,
        payload: status
    };
}

export function setEmailAction(email: string): AuthenticationActionTypes {
    return {
        type: SET_EMAIL,
        payload: email
    };
}


export function logoutAction(): AuthenticationActionTypes {
    return {
        type: LOGOUT
    };
}
