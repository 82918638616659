import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {
    IonAvatar, IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRefresher,
    IonRefresherContent, RefresherEventDetail
} from "@ionic/react";
import S3Image from "../components/S3Image";
import {LocationService} from "../services/LocationService";
import {Location} from '../interfaces/Location'
import {Room} from "../interfaces/Room";
import Footer from "../shared/Footer";

const IframeLocation: React.FC = () => {

    const {id, slug}: any = useParams();
    const [location, setLocation] = useState<Location>();


    const doRefresh = (event: CustomEvent<RefresherEventDetail> | null) => {
        LocationService.getLocationBySlug(id, slug)
            .then((r: Location) => {
                setLocation(r)
            })
            .finally(() => {
                if (event) {
                    event.detail.complete()
                }
            })
    }


    useEffect(() => {
        doRefresh(null);
    }, [])

    return <IonPage>
        <IonContent>

            <IonList>
                {location?.rooms.map((r: Room, index: number) => {
                    return <IonItem key={'location-' + index} href={'/iframe/room/' + id + '/' + r.id}>
                        <IonAvatar slot="start">
                            <S3Image name={r.name + ' image'} fileName={r.image}/>
                        </IonAvatar>
                        <IonLabel>{r.name}</IonLabel>
                    </IonItem>
                })}
            </IonList>

        </IonContent>
        <Footer/>
    </IonPage>
}

export default IframeLocation;