import {Table} from "../../interfaces/Table";
export const SET_BOOKING = 'SET_BOOKING';
export const RESET_BOOKING = 'RESET_BOOKING';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export interface BookingState {
    date: any,
    tables: Table[],
    notes: string,
    roomID: undefined | string,
    locationID: undefined | string,
    owner?: boolean,
    isModalOpen: boolean
}

interface setBooking {
    type: typeof SET_BOOKING,
    payload: any
}

interface resetBooking {
    type: typeof RESET_BOOKING
}

interface closeModal{
    type: typeof CLOSE_MODAL
}

interface openModal{
    type: typeof OPEN_MODAL
}

export type IframeActionTypes = setBooking | resetBooking | closeModal | openModal;
