import React, {useState} from 'react';
import {Step} from "../interfaces/Step";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Stepper: React.FC<{ steps: Step[], currentStep : number, setCurrentStep: any }> = (
    {
        currentStep,
        setCurrentStep,
        steps
    }) => {

    return <div style={{
        marginTop: 15,
    }}>
        <div style={{
            position: 'relative',
            height: 75,
            display: 'flex',
            maxWidth: '80%',
            margin: 'auto',
            justifyContent: 'space-between'
        }}>
            <div style={{position: "absolute", top: 37, borderTop: '2px solid gray', left: 0, right: 0, zIndex: -1}}>
            </div>
            {steps.map((s: any, index: number) => <div
                    key={'step-' + index}
                    title={s.name}
                    style={{
                        padding: 5,
                        background: "white",
                        width: 75
                    }}>
                    <div style={{
                        background: index === currentStep ? '#3880ff' : '#ededed',
                        padding: 15,
                        color:  index === currentStep ? "white" : "black",
                        borderRadius: '100%',
                        textAlign: 'center',
                    }}>
                        <FontAwesomeIcon icon={s.icon} size={'2x'}/>
                    </div>
                </div>
            )}
        </div>
        <div style={{
            color: 'black',
            marginTop: 10,
            paddingTop: 6,
            paddingBottom: 6,
            marginBottom: 10,
            textAlign: 'center',
            borderRadius: 5,
            border: '2px solid #dbdbff'
        }}>
            {steps[currentStep].name}
        </div>
        {steps[currentStep].element}
    </div>
}
export default Stepper;