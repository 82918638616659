import {MessageActionTypes, RESET, SET_MESSAGE} from "../types/MessageTypes";


export function setMessageAction(text: string | string[], type: 'success' | 'info' | 'warning' | 'danger'): MessageActionTypes {
    return {
        type: SET_MESSAGE,
        payload: {text: text, type: type}
    };
}

export function resetMessageAction(): MessageActionTypes {
    return {
        type: RESET
    };
}