import {createReducer} from '@reduxjs/toolkit';
import {BookingState, CLOSE_MODAL, OPEN_MODAL, RESET_BOOKING, SET_BOOKING} from "../types/IframeTypes";


const initialState: BookingState = {
    tables: [],
    date: null,
    notes: '',
    roomID: undefined,
    locationID: undefined,
    owner: false,
    isModalOpen: false
};


export const iframeReducer = createReducer(initialState, (builder => {
    builder
        .addCase(SET_BOOKING, (state: BookingState, action: any) => {
            state.tables = action.payload.tables;
            state.date = action.payload.date;
            state.notes = action.payload.notes;
            state.roomID = action.payload.roomID;
            state.locationID = action.payload.locationID;
            if (action.payload.owner) {
                state.owner = action.payload.owner;
            }
        })
        .addCase(RESET_BOOKING, (state: BookingState, action: any) => {
            state.tables = [];
            state.date = null;
            state.notes = '';
            state.roomID = undefined;
            state.locationID = undefined;
            state.owner = false;
            state.isModalOpen = false;
        })
        .addCase(CLOSE_MODAL, (state: BookingState, action: any) => {
            state.isModalOpen = false
        })
        .addCase(OPEN_MODAL, (state: BookingState, action: any) => {
            state.isModalOpen = true
        })
    ;
}));
